export function mascararEmail(email: string): string {
  const partesEmail = email.split('@');
  const nomeUsuario = partesEmail[0];
  const dominio = partesEmail[1];

  const letrasIniciais = nomeUsuario.substr(0, 3);
  const letrasRestantes = nomeUsuario.substr(3);

  const emailMascarado = `${
    letrasIniciais + letrasRestantes.replace(/[a-zA-Z@#$%!-.]/g, '*')
  }@${dominio}`;

  return emailMascarado;
}

export function mascararCelular(celular: string): string {
  const digitosIniciais = celular.substr(0, 3);
  const digitosRestantes = celular.substr(3);

  const celularMascarado =
    digitosIniciais + digitosRestantes.replace(/\d/g, '*');

  return celularMascarado;
}

export function mascararDocumento(
  tipoPessoa: number,
  documento: string
): string {
  let mascara = '';

  if (tipoPessoa === 1) {
    // Máscara para pessoa física (CPF)
    mascara = documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else if (tipoPessoa === 2) {
    // Máscara para pessoa jurídica (CNPJ)
    mascara = documento.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  } else {
    throw new Error(
      'Tipo de pessoa inválido. Use 1 para pessoa física ou 2 para pessoa jurídica.'
    );
  }

  return mascara;
}

export function formatarMoeda(valor: number): string {
  const formatoMoeda = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });

  return formatoMoeda.format(valor);
}

// Função para formatar CEP na máscara 00000-000
export function formatarCEP(cep: string): string {
  // Remover caracteres não numéricos do CEP, mantendo apenas os números
  const cepNumerico = cep.replace(/\D/g, '');

  // Aplicar a máscara 00000-000 ao CEP numérico
  return cepNumerico.replace(/^(\d{5})(\d{3})$/, '$1-$2');
}

export function formatarMedidaGenerico(valor: number, unidade: string): string {
  const formatoMedida = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `${formatoMedida.format(valor)} ${unidade}`;
}

export function formatarDistancia(
  valorEmCm: number,
  unidade: 'cm' | 'm'
): string {
  let valorFormatado: string;

  if (unidade === 'm') {
    const valorEmMetros = valorEmCm / 100; // Converter de cm para m
    valorFormatado = new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(valorEmMetros);
    valorFormatado += ' m';
  } else {
    valorFormatado = new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(valorEmCm);
    valorFormatado += ' cm';
  }

  return valorFormatado;
}

export function validarCPF(cpfMasked: string): boolean {
  const cpf = cpfMasked.replace(/\D/g, '');

  if (cpf.length !== 11) {
    return false;
  }

  if (/^(\d)\1{10}$/.test(cpf)) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 9; i += 1) {
    sum += parseInt(cpf.charAt(i), 10) * (10 - i);
  }
  let remainder = 11 - (sum % 11);
  const firstDigit = remainder >= 10 ? 0 : remainder;

  if (parseInt(cpf.charAt(9), 10) !== firstDigit) {
    return false;
  }

  sum = 0;
  for (let i = 0; i < 10; i += 1) {
    sum += parseInt(cpf.charAt(i), 10) * (11 - i);
  }
  remainder = 11 - (sum % 11);
  const secondDigit = remainder >= 10 ? 0 : remainder;

  if (parseInt(cpf.charAt(10), 10) !== secondDigit) {
    return false;
  }

  return true;
}

export function validarTelefone(telefoneMasked: string): boolean {
  // Removendo caracteres não numéricos
  const telefone = telefoneMasked.replace(/\D/g, '');

  // Verificando se o telefone tem 10 ou 11 dígitos (DDD + número principal)
  if (telefone.length !== 10 && telefone.length !== 11) {
    return false;
  }

  // Verificando se o telefone é uma sequência repetida (ex: "1111111111")
  if (/^(\d)\1+$/g.test(telefone)) {
    return false;
  }

  return true;
}

export function converterData(dataString: Date): Date {
  const dateUTC = new Date(dataString);
  const retorno = new Date(dateUTC.toISOString());
  return retorno;
}

export function formatarTelefone(telefone: string): string {
  // Remover todos os caracteres não numéricos do telefone
  const telefoneNumerico = telefone.replace(/\D/g, '');

  if (telefoneNumerico.length <= 10) {
    // Formatar telefone no padrão (11) 2345-1234
    return telefoneNumerico.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
  }
  // Formatar telefone no padrão (11) 12345-1234
  return telefoneNumerico.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
}

export const cellphoneMask = (value: string): string => {
  if (value.replace(/\D+/g, '').length < 11)
    return value
      .replace(/\D+/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+$/, '$1');
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+$/, '$1');
};

export const moneyMask = (
  input: string | number,
  currencyDescriptor: string,
  minimumFractionDigits: number
): string => {
  const options = { minimumFractionDigits };
  if (typeof input === 'string') {
    const formatedValue = input.replace(/[^0-9-]+/g, '').replace(',', '.');
    if (formatedValue.length === 0) {
      return `${currencyDescriptor !== '' ? `${currencyDescriptor} ` : ''}0,00`;
    }
    let aux = 1;
    for (let i = 0; i < minimumFractionDigits; i += 1) {
      aux *= 10;
    }
    const toFormat = parseFloat(formatedValue) / aux;
    const result = new Intl.NumberFormat('pt-BR', options).format(toFormat);
    return `${
      currencyDescriptor !== '' ? `${currencyDescriptor} ` : ''
    }${result}`;
  }
  return `${
    currencyDescriptor !== '' ? `${currencyDescriptor} ` : ''
  }${new Intl.NumberFormat('pt-BR', options).format(
    +input.toFixed(minimumFractionDigits)
  )}`;
};

export const cpfMask = (value: string): string => {
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+$/, '$1');
};

export const cepMask = (value: string): string => {
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+$/, '$1');
};

export const plateMask = (value: string): string => {
  return value
    .replace(/[^a-zA-Z0-9]/g, '')
    .slice(0, 7)
    .toUpperCase();
};

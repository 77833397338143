import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import React from 'react';
import { ITipoVeiculo } from '../../../../interfaces/response/TipoVeiculo/ITipoVeiculo';
import useVehicleTypeTable, { Header } from './hooks/useVehicleTypeTable';

export interface IVehicleTableProps {
  vehicleTypes: ITipoVeiculo[];
  onRowClick?: (vehicleType: ITipoVeiculo) => void;
}

const VehicleTypeTable: React.FC<IVehicleTableProps> = ({
  vehicleTypes,
  onRowClick,
}): JSX.Element => {
  const {
    cellData,
    columns,
    handleRowClick,
    setSortDescriptor,
    sortDescriptor,
    sortedVehicleTypes,
  } = useVehicleTypeTable({ vehicleTypes, onRowClick });

  return (
    <Table
      aria-label="Tabela de tipo de veículo"
      selectionMode="single"
      onRowAction={handleRowClick}
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
    >
      <TableHeader columns={columns}>
        {({ key, name }) => (
          <TableColumn key={key} allowsSorting>
            {name}
          </TableColumn>
        )}
      </TableHeader>

      <TableBody
        items={sortedVehicleTypes}
        emptyContent="Nenhum tipo de vículo no momento"
      >
        {(item) => (
          <TableRow key={item.idTipoVeiculo}>
            {(columnKey) => (
              <TableCell>
                <p className="text-foreground-600">
                  {cellData(item, columnKey as Header)}
                </p>
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default VehicleTypeTable;

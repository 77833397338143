import { Spinner } from '@nextui-org/react';
import React from 'react';
import BlankState from '../../../components/BlankState/BlankState';
import CardDetalhePedidos from './components/CardDetalhePedidos/CardDetalhePedidos';
import UseDetalheRota from './hooks/UseDetalheRota';

const DetalheRota = (): JSX.Element => {
  const {
    route,
    loading,
    handleApproveRoute,
    handleRejectRoute,
    loadingApproval,
    loadingReject,
  } = UseDetalheRota();

  if (loading) return <Spinner label="Baixando conteúdo" color="default" />;

  if (!route) return <BlankState label="Rota nao encontrada" />;

  return (
    <CardDetalhePedidos
      handleApproveRoute={handleApproveRoute}
      handleRejectRoute={handleRejectRoute}
      loadingApproval={loadingApproval}
      loadingReject={loadingReject}
      rota={route}
    />
  );
};

export default DetalheRota;

import { Divider, Spacer } from '@nextui-org/react';
import React from 'react';
import { Outlet } from 'react-router-dom';

const PedidosLayout = (): JSX.Element => {
  return (
    <div className="flex grow flex-col">
      {/* Title */}
      <h3 className="text-2xl font-bold text-foreground-800">
        Gestão de pedidos
      </h3>

      <Divider className="my-4" />
      {/*
      <Tabs
        variant="underlined"
        selectedKey={pathname}
        onSelectionChange={(key) => handleRedirectTo(key.toString())}
      >
        {tabOptions.map(({ name, path }) => (
          <Tab key={path} title={name} />
        ))}
      </Tabs> */}

      <Spacer y={4} />

      <Outlet />
    </div>
  );
};

export default PedidosLayout;

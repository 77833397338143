import { zodResolver } from '@hookform/resolvers/zod';
import { useDisclosure } from '@nextui-org/react';
import { useContext, useState } from 'react';
import {
  Control,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import toast from 'react-hot-toast';
import { z } from 'zod';
import {
  Theme,
  ThemeContext,
  ThemeContextType,
} from '../../../contexts/ThemeContext/ThemeContext';
import { ICadastraVeiculoMotoristaRequest } from '../../../interfaces/request/VeiculoMotorista/ICadastraVeiculoMotoristaRequest';
import { AtribuirVeiculoMotorista } from '../../../redux/features/clientsData/clientsDataThunk';
import { useAsyncDispatch } from '../../../redux/store';

const addVehicleSchema = z.object({
  vehicle: z.string().min(1, 'Selecione um veículo'),
  isDefault: z.boolean().optional(),
});

type AddVehicleSchema = z.infer<typeof addVehicleSchema>;

interface UseAddVehicleModalProps {
  idMotorista: number;
  onUpdate: () => void;
}

export interface UseAddVehicleModal {
  control: Control<AddVehicleSchema>;
  handleAddVehicle: (data: AddVehicleSchema) => Promise<void>;
  handleClose: () => void;
  handleSubmit: UseFormHandleSubmit<AddVehicleSchema>;
  isLoading: boolean;
  isOpen: boolean;
  isValid: boolean;
  onOpen: () => void;
  register: UseFormRegister<AddVehicleSchema>;
  theme: Theme;
}

const useAddVehicleModal = ({
  idMotorista,
  onUpdate,
}: UseAddVehicleModalProps): UseAddVehicleModal => {
  const dispatch = useAsyncDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { theme } = useContext<ThemeContextType>(ThemeContext);
  const defaultValues: AddVehicleSchema = {
    vehicle: '',
    isDefault: false,
  };
  const { register, handleSubmit, formState, reset, control } =
    useForm<AddVehicleSchema>({
      resolver: zodResolver(addVehicleSchema),
      defaultValues,
      mode: 'onTouched',
      reValidateMode: 'onBlur',
      resetOptions: {
        keepErrors: false,
      },
    });
  const { isValid } = formState;

  const handleClose = (): void => {
    onClose();
    reset();
  };

  const handleAddVehicle = async (data: AddVehicleSchema): Promise<void> => {
    setIsLoading(true);
    const { vehicle, isDefault } = data;

    const body: ICadastraVeiculoMotoristaRequest = {
      idMotorista,
      idVeiculo: parseInt(vehicle, 10),
      veiculoPadrao: isDefault ?? false,
    };

    await dispatch(
      AtribuirVeiculoMotorista({
        body,
      })
    )
      .unwrap()
      .then(() => onUpdate())
      .catch(() => {
        toast.error(
          'Erro ao atribuir veículo ao motorista, por favor tente mais tarde'
        );
      })
      .finally(() => {
        setIsLoading(false);
        onClose();
        reset();
      });
  };

  return {
    control,
    handleAddVehicle,
    handleClose,
    handleSubmit,
    isLoading,
    isOpen,
    isValid,
    onOpen,
    register,
    theme,
  };
};

export default useAddVehicleModal;

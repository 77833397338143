import { Link, SortDescriptor, Spinner, Tooltip } from '@nextui-org/react';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { FaCheckCircle, FaFileDownload } from 'react-icons/fa';
import { IPlanilha } from '../../../../../../interfaces/response/Planilha/IPlanilha';
import { formatarData } from '../../../../../../util/format';

export type Header =
  | 'Nome Arquivo Original'
  | 'ID Cliente'
  | 'ID Remessa Pedido'
  | 'Usuário Importação'
  | 'Data de importação'
  | 'Ind Processada'
  | 'Ações';

type Column = {
  key: Header;
  name: string;
};

export interface UseSpreadsheetListTableProps {
  spreadsheets: IPlanilha[];
}

export interface UseSpreadsheetListTable {
  cellData: (
    spreadsheet: IPlanilha,
    columnKey: Header
  ) => number | string | JSX.Element;
  columns: Column[];
  setSortDescriptor: Dispatch<SetStateAction<SortDescriptor>>;
  sortDescriptor: SortDescriptor;
  sortedSpreadsheets: IPlanilha[];
}

const useSpreadsheetListTable = ({
  spreadsheets,
}: UseSpreadsheetListTableProps): UseSpreadsheetListTable => {
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'Ordem de entrega',
    direction: 'descending',
  });

  const columns: Column[] = [
    {
      key: 'Nome Arquivo Original',
      name: 'NOME ARQUIVO',
    },
    {
      key: 'ID Cliente',
      name: 'CLIENTE',
    },
    {
      key: 'ID Remessa Pedido',
      name: 'REMESSA PEDIDO',
    },
    {
      key: 'Usuário Importação',
      name: 'IMPORTADO POR',
    },
    {
      key: 'Data de importação',
      name: 'DATA DE IMPORTAÇÃO',
    },
    {
      key: 'Ind Processada',
      name: 'PROCESSAMENTO',
    },
    {
      key: 'Ações',
      name: 'AÇÕES',
    },
  ];

  const cellData = (
    spreadsheet: IPlanilha,
    columnKey: Header
  ): number | string | JSX.Element => {
    switch (columnKey) {
      case 'Nome Arquivo Original':
        return spreadsheet.nomeArquivoOriginal;
      case 'ID Cliente':
        return spreadsheet.idCliente;
      case 'ID Remessa Pedido':
        return spreadsheet.idRemessaPedido;
      case 'Usuário Importação':
        return spreadsheet.usuarioImportacao;
      case 'Data de importação':
        return formatarData(spreadsheet.dataImportacao) ?? '';
      case 'Ind Processada':
        return spreadsheet.indProcessada ? (
          <FaCheckCircle className="text-green-500" />
        ) : (
          <div className="flex flex-row items-center justify-center gap-2">
            <Spinner size="sm" />

            <p>Processando</p>
          </div>
        );
      case 'Ações':
        return (
          <div className="flex items-center justify-center">
            <Tooltip
              showArrow
              content="Download"
              className="rounded px-4 py-2"
              offset={6}
              color="foreground"
            >
              <Link
                href={spreadsheet.urlArquivoCloud}
                aria-label="downlaod"
                target="_blank"
                download
                rel="noreferrer"
                className="text-foreground-700"
              >
                <FaFileDownload size={16} />
              </Link>
            </Tooltip>
          </div>
        );
      default:
        return '';
    }
  };

  const sortedSpreadsheets = useMemo(() => {
    return [...spreadsheets].sort((a: IPlanilha, b: IPlanilha) => {
      const first = cellData(a, sortDescriptor.column as Header) as number;
      const second = cellData(b, sortDescriptor.column as Header) as number;

      let cmp = 0;
      if (first < second) cmp = -1;
      if (first > second) cmp = 1;

      return sortDescriptor.direction === 'ascending' ? -cmp : cmp;
    });
  }, [sortDescriptor, spreadsheets]);

  return {
    cellData,
    columns,
    setSortDescriptor,
    sortDescriptor,
    sortedSpreadsheets,
  };
};

export default useSpreadsheetListTable;

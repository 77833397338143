import React, { FC } from 'react';
import { Deposito } from '../../../../interfaces/IUsuario';

export interface WarehouseTooltipCardProps {
  warehouse: Deposito | undefined;
}

const WarehouseTooltipCard: FC<WarehouseTooltipCardProps> = ({
  warehouse,
}): JSX.Element => {
  return (
    <div className="p-0.5">
      {/* Description */}
      <p className="mb-1">
        <span className="font-bold">{warehouse?.descricao}</span>
      </p>

      {/* Value */}
      <p className="max-w-xs">{warehouse?.endereco.logradouro}</p>
    </div>
  );
};

export default WarehouseTooltipCard;

import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import {
  Control,
  FieldErrors,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import toast from 'react-hot-toast';
import { z } from 'zod';
import {
  Theme,
  ThemeContext,
} from '../../../../../contexts/ThemeContext/ThemeContext';
import { Deposito, Endereco } from '../../../../../interfaces/IUsuario';
import {
  IDetalheRotaRequest,
  Waypoint,
} from '../../../../../interfaces/request/DetalheRota/IDetalheRotaRequest';
import { IDetalhesRota } from '../../../../../interfaces/response/DetalheRota/IDetalheRotaResponse';
import { IMotorista } from '../../../../../interfaces/response/Motorista/IMotorista';
import { IVeiculoMotorista } from '../../../../../interfaces/response/VeiculoMotorista/IVeiculoMotorista';
import {
  ObterMotoristas,
  ObterVeiculosMotorista,
} from '../../../../../redux/features/clientsData/clientsDataThunk';
import { setReturnToWarehouse } from '../../../../../redux/features/generalData/generalDataSlice';
import { postRoute } from '../../../../../redux/features/rotas/rotasThunk';
import { useAsyncDispatch } from '../../../../../redux/store';
import getRandomHexColor from '../../../../../util/colorGenerator';
import { RouteError } from '../../../hooks/useCreateRoutes';
import { ConfirmationModalHandle } from '../components/ConfirmationModal/ConfirmationModal';

const formSchema = z.object({
  name: z.string().min(1, 'CEP inicial é obrigatório'),
  driver: z.string().min(1, 'Motorista é obrigatório'),
  vehicle: z.string().min(1, 'Veículo é obrigatório'),
  departureDate: z
    .date({ invalid_type_error: 'Data de saída é necessária' })
    .min(
      new Date(new Date().getDate() - 1),
      'Data de saída não pode ser menor que hoje'
    ),
  returnToWarehouse: z.boolean(),
});

type FormSchema = z.infer<typeof formSchema>;

export interface UseCreateRouteAccordionProps {
  updateDriver: (driver: IMotorista | undefined) => void;
  updateVehicle: (vehicle: IVeiculoMotorista | undefined) => void;
  updateReturnWarehouse: (returnWarehouse: boolean) => void;
  onRouteCreated: () => void;
  warehouse: Deposito | undefined;
  routeErrors: Set<RouteError>;
  confirmModalRef: React.RefObject<ConfirmationModalHandle>;
  routeDetails: IDetalhesRota | undefined;
  isLoadingRouteDetails: boolean;
}

export interface UseCreateRouteAccordion {
  control: Control<FormSchema>;
  driver: string;
  returnWarehouse: boolean;
  drivers: IMotorista[];
  errors: FieldErrors<FormSchema>;
  handleSubmit: UseFormHandleSubmit<FormSchema>;
  handleSubmitRoute: (data: FormSchema) => Promise<void>;
  isDriversLoading: boolean;
  isValid: boolean;
  isVehiclesLoading: boolean;
  loading: boolean;
  name: string;
  register: UseFormRegister<FormSchema>;
  theme: Theme;
  vehicle: string;
  vehicles: IVeiculoMotorista[];
  isLoadingRouteDetails: boolean;
}

const useCreateRouteAccordion = ({
  updateDriver,
  updateVehicle,
  updateReturnWarehouse,
  onRouteCreated,
  warehouse,
  routeErrors,
  confirmModalRef,
  routeDetails,
  isLoadingRouteDetails,
}: UseCreateRouteAccordionProps): UseCreateRouteAccordion => {
  const dispatch = useAsyncDispatch();
  const { theme } = useContext(ThemeContext);
  const [drivers, setDrivers] = useState<IMotorista[]>([]);
  const [vehicles, setVehicles] = useState<IVeiculoMotorista[]>([]);
  const [isDriversLoading, setIsDriversLoading] = useState<boolean>(true);
  const [isVehiclesLoading, setIsVehiclesLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const defaultValues: Partial<FormSchema> = {
    name: '',
    driver: '',
    vehicle: '',
    departureDate: undefined,
    returnToWarehouse: true,
  };
  const { register, handleSubmit, formState, control, watch, setValue } =
    useForm<FormSchema>({
      defaultValues,
      resolver: zodResolver(formSchema),
      mode: 'onTouched',
      reValidateMode: 'onChange',
    });
  const { errors, isValid } = formState;
  const { driver, vehicle, name, returnToWarehouse } = watch();

  const getDrivers = async (): Promise<void> => {
    setIsDriversLoading(true);
    await dispatch(ObterMotoristas())
      .unwrap()
      .then((response) => setDrivers(response.motoristas))
      .catch((error) => {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_CANCELED') return;

        toast.error('Erro ao carregar pedidos, por favor tente mais tarde');
      })
      .finally(() => setIsDriversLoading(false));
  };

  const setDefaultDriverVehicle = (
    driverVehicles: IVeiculoMotorista[]
  ): void => {
    const defaultVehicle = driverVehicles.find(
      (auxVehicle) => auxVehicle.veiculoPadrao
    );

    if (defaultVehicle)
      setValue('vehicle', defaultVehicle.idVeiculoMotorista.toString());
    else setValue('vehicle', '');
  };

  const getDriverVehicles = async (
    selectedDriver: IMotorista
  ): Promise<void> => {
    setIsVehiclesLoading(true);
    await dispatch(
      ObterVeiculosMotorista({ idMotorista: selectedDriver.idMotorista })
    )
      .unwrap()
      .then((response) => {
        const driverVehicles: IVeiculoMotorista[] = response.veiculosMotorista;
        setVehicles(driverVehicles);
        setDefaultDriverVehicle(driverVehicles);
      })
      .catch((error) => {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_CANCELED') return;

        toast.error('Erro ao carregar pedidos, por favor tente mais tarde');
      })
      .finally(() => setIsVehiclesLoading(false));
  };

  useEffect(() => {
    const driverSelected = drivers.find(
      (driverAux) => driverAux.idMotorista.toString() === driver
    );

    if (driver === '' || !driverSelected) {
      updateDriver(undefined);
      setValue('vehicle', '');
    } else {
      updateDriver(driverSelected);
      getDriverVehicles(driverSelected);
    }
  }, [driver]);

  useEffect(() => {
    const vehicleSelected = vehicles.find(
      (vehicleAux) => vehicleAux.idVeiculoMotorista.toString() === vehicle
    );

    if (vehicle === '' || !vehicleSelected) updateVehicle(undefined);
    else updateVehicle(vehicleSelected);
  }, [vehicle]);

  useEffect(() => {
    dispatch(setReturnToWarehouse(returnToWarehouse));
  }, [returnToWarehouse]);

  useEffect(() => {
    getDrivers();
  }, []);

  const handleSubmitRoute = async (data: FormSchema): Promise<void> => {
    setLoading(true);

    if (routeErrors.size && confirmModalRef.current) {
      let userAcceptance = false;
      await confirmModalRef.current
        .confirmAction()
        .then(() => {
          userAcceptance = true;
        })
        .catch(() => {
          userAcceptance = false;
        });

      if (!userAcceptance) {
        setLoading(false);
        return;
      }
    }

    const {
      departureDate,
      driver: driverId,
      name: routeName,
      vehicle: vehicleId,
    } = data;

    const driverSelected = drivers.find(
      (driverAux) => driverAux.idMotorista.toString() === driverId
    );

    const vehicleSelected = vehicles.find(
      (vehicleAux) => vehicleAux.idVeiculoMotorista.toString() === vehicleId
    );

    if (!driverSelected || !vehicleSelected || !routeDetails || !warehouse)
      return;

    const waypoints: Waypoint[] = routeDetails.pedidos.map(
      (order) =>
        ({
          idCte: order.idCte,
          vlTotalTributo: order.vlTotalTributo,
          vlCarga: order.vlCarga,
          vlCargaAverbado: order.vlCargaAverbado,
          pesoReal: order.pesoReal,
          unidadeMedidaPesoReal: order.unidadeMedidaPesoReal,
          pesoCubado: order.pesoCubado,
          unidadeMedidaPesoCubado: order.unidadeMedidaPesoCubado,
          pesoTaxado: order.pesoTaxado,
          unidadeMedidaPesoTaxado: order.unidadeMedidaPesoTaxado,
          adValorem: order.adValorem,
          fretePeso: order.fretePeso,
          gRIS: order.gRIS,
          impostoRepassado: order.impostoRepassado,
          vlTotalPrestacao: order.vlTotalPrestacao,
          vlTotalReceber: order.vlTotalReceber,
          vTotTribICMS: order.vTotTribICMS,
          volumes: order.volumes,
          volume: order.volume,
          unidadeMedidaVolume: order.unidadeMedidaVolume,
          chaveNFe: order.chaveNFe,
          dtEmissao: order.dtEmissao,
          dtLimiteEntrega: order.dtLimiteEntrega,
          logradouro: order.logradouro,
          numero: order.numero,
          complemento: order.complemento,
          bairro: order.bairro,
          cidade: order.cidade,
          estado: order.estado,
          lat: order.lat,
          lng: order.lng,
          cep: order.cep,
        } as Waypoint)
    );

    let destino: Endereco = warehouse.endereco;
    const origem: Endereco = warehouse.endereco;

    if (!returnToWarehouse)
      destino = {
        idEndereco: '',
        logradouro: driverSelected?.endereco || '',
        numero: driverSelected?.numero || '',
        complemento: driverSelected?.complemento || '',
        bairro: driverSelected?.bairro || '',
        cidade: driverSelected?.cidade || '',
        estado: '',
        lat: driverSelected?.lat || '',
        lng: driverSelected?.lng || '',
        cep: '',
      };

    const body: IDetalheRotaRequest = {
      idCliente: warehouse.idCliente,
      idDeposito: warehouse.idDeposito,
      dataSaida: departureDate,
      descricao: name,
      idMotoristaVeiculo: vehicleSelected.idVeiculoMotorista || 0,
      destino,
      origem,
      pedidos: waypoints,
      voltaParaDeposito: returnToWarehouse,
      polylineColor: getRandomHexColor(),
    };

    await dispatch(postRoute({ body }))
      .unwrap()
      .then(() => {
        onRouteCreated();
        toast.success(`Rota ${routeName} criada com sucesso`);
      })
      .catch((error) => {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_CANCELED') return;

        toast.error('Erro ao criar rota, por favor tente mais tarde');
      })
      .finally(() => setLoading(false));
  };

  return {
    control,
    driver,
    drivers,
    errors,
    handleSubmit,
    handleSubmitRoute,
    isDriversLoading,
    isValid,
    isVehiclesLoading,
    loading,
    name,
    register,
    theme,
    vehicle,
    vehicles,
    isLoadingRouteDetails,
    returnWarehouse: returnToWarehouse,
  };
};

export default useCreateRouteAccordion;

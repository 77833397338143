import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import React from 'react';
import { IOrderCheckResponse } from '../../../../interfaces/response/Orders/IOrderCheckResponse';
import usePrintedOrderTable, { Header } from './hooks/usePrintedOrderTable';

export interface IPrintedOrderTable {
  printedOrders: IOrderCheckResponse[];
  onPrintOrder: (order: IOrderCheckResponse) => void;
}

const PrintedOrderTable: React.FC<IPrintedOrderTable> = ({
  onPrintOrder,
  printedOrders,
}): JSX.Element => {
  const { cellData, columns, handleRowClick } = usePrintedOrderTable({
    onPrintOrder,
    printedOrders,
  });

  return (
    <Table
      aria-label="Etiquetas impressas"
      selectionMode="single"
      onRowAction={handleRowClick}
    >
      <TableHeader columns={columns}>
        {({ key, name }) => <TableColumn key={key}>{name}</TableColumn>}
      </TableHeader>

      <TableBody items={printedOrders} emptyContent="Nenhuma etiqueta impressa">
        {(item) => (
          <TableRow key={item.chaveNFe}>
            {(columnKey) => (
              <TableCell>
                <p className="text-foreground-600">
                  {cellData(item, columnKey as Header)}
                </p>
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default PrintedOrderTable;

/* eslint-disable react/no-array-index-key */
import { Input, Spinner } from '@nextui-org/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import RegisterDriverModal from '../../../components/RegisterDriverModal/RegisterDriverModal';
import { IMotorista } from '../../../interfaces/response/Motorista/IMotorista';
import DriverListTable from '../components/DriverListTable/DriverListTable';
import useMotoristas from './hooks/useMotoristas';

const Motorista = (): JSX.Element => {
  const { motoristas, setFilter, loading, handleGetDrivers } = useMotoristas();
  const navigate = useNavigate();

  const handleDriverSelection = (motorista: IMotorista): void => {
    navigate(`/cadastros/motorista/${motorista.idMotorista}`, {
      state: motorista,
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-end justify-between">
        <p className="text-default-500">
          Selecione o motorista na tabela para ver seus detalhes:
        </p>

        <div className="grid grid-cols-2 items-end gap-8 ">
          <Input
            size="md"
            type="text"
            label="Filtro"
            variant="underlined"
            placeholder="Nome"
            autoFocus
            classNames={{
              label: 'font-bold',
            }}
            onChange={(e) => setFilter(e.target.value)}
          />

          <RegisterDriverModal onUpdate={handleGetDrivers} />
        </div>
      </div>

      {loading && <Spinner label="Baixando conteúdo" color="default" />}

      {!loading && (
        <DriverListTable
          drivers={motoristas}
          onDriverClick={handleDriverSelection}
        />
      )}
    </div>
  );
};

export default Motorista;

/* eslint-disable import/no-unresolved */
import {
  AnyAction,
  applyMiddleware,
  configureStore,
  Dispatch,
  EmptyObject,
} from '@reduxjs/toolkit';
import { useDispatch as useStoreDispatch } from 'react-redux';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/es/storage';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { AppState, rootReducer } from './reducer';

const preloadedState: AppState = {} as AppState;

const persistConfig = {
  key: 'root',
  storage: localStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleWareEnhancer = applyMiddleware(thunk);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  preloadedState,
  enhancers: [middleWareEnhancer],
});

export type RootState = ReturnType<typeof store.getState>;

export const useAsyncDispatch = (): ThunkDispatch<
  EmptyObject & AppState & PersistPartial,
  undefined,
  AnyAction
> &
  Dispatch<AnyAction> => useStoreDispatch<typeof store.dispatch>();

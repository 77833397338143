import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
} from '@nextui-org/react';
import React, { forwardRef, useContext, useImperativeHandle } from 'react';
import { IRota } from '../../../../../interfaces/response/Rotas/IRota';
import { Context } from '../../../../../layouts/GerirRotasLayout/GerirRotasLayout';
import OrderListTable from '../OrderListTable/OrderListTable';
import { useRouteOrderListModal } from './hooks/useRouteOrderListModal';

export interface IRouteOrderListModalHandle {
  openModal: (selectedRoute: IRota) => void;
  closeModal: () => void;
}

const RouteOrderListModal: React.ForwardRefRenderFunction<
  IRouteOrderListModalHandle
> = (_, ref): JSX.Element => {
  const { refreshRoutes } = useContext(Context);
  const {
    isOpen,
    onOpenChange,
    theme,
    route,
    handleOpenModal,
    handleCloseModal,
    onSelectionChange,
  } = useRouteOrderListModal();

  useImperativeHandle(ref, () => ({
    openModal: (selectedRoute: IRota) => {
      handleOpenModal(selectedRoute);
    },
    closeModal: () => {
      handleCloseModal();
      refreshRoutes();
    },
  }));

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className={`${theme} max-h-[80%] max-w-[90%]`}
      onClose={handleCloseModal}
    >
      <ModalContent>
        {/* Header */}
        <ModalHeader className="text-default-600">Rota Cancelada</ModalHeader>

        {/* Body */}
        <ModalBody>
          {/* Loading Spinner */}
          {route === null && <Spinner />}
          {/* Information text */}
          {route && (
            <div className="flex flex-row items-center justify-between">
              <p className="whitespace-nowrap text-sm text-default-500">
                <span className="font-bold">{route.descricao}</span>
              </p>
            </div>
          )}
          {/* Order Table */}{' '}
          {route && route.pedidos && (
            <OrderListTable
              onSelectionChange={onSelectionChange}
              orders={route.pedidos}
            />
          )}
        </ModalBody>

        {/* Footer */}
        <ModalFooter>
          <Button color="danger" onPress={handleCloseModal}>
            Voltar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default forwardRef(RouteOrderListModal);

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICliente } from '../../../interfaces/ICliente';
import { ClientsDataState } from './clientsDataTypes';

const initialState: ClientsDataState = {
  clientsData: undefined,
  clientsDataStatus: 'idle',
  userDataStatus: 'idle',
};

const clientsDataSlice = createSlice({
  name: 'clientsData',
  initialState,
  reducers: {
    adicionaCliente(state: ClientsDataState, action: PayloadAction<ICliente>) {
      const aux = state.clientsData;
      aux?.push(action.payload);
      state.clientsData = aux;
    },
    editClient(state: ClientsDataState, action: PayloadAction<ICliente>) {
      const clienteExists = state.clientsData?.some(
        (cli) => cli.idCliente === action.payload.idCliente
      );

      if (clienteExists) {
        const clients = state.clientsData?.map((cli) => {
          if (cli.idCliente === action.payload.idCliente) {
            return action.payload;
          }

          return cli;
        });

        state.clientsData = clients;
      }
    },
  },
  extraReducers: () => {},
});

export const { adicionaCliente, editClient } = clientsDataSlice.actions;

export default clientsDataSlice;

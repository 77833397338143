import {
  BreadcrumbItem,
  Breadcrumbs,
  Divider,
  Spacer,
} from '@nextui-org/react';
import React from 'react';
import { Outlet } from 'react-router-dom';

const DetalheRotaLayout = (): JSX.Element => {
  return (
    <div className="flex grow flex-col">
      {/* Title */}
      <h1 className="text-2xl font-bold text-foreground-800">Detalhes Rota</h1>

      <Divider className="my-4" />

      <Breadcrumbs>
        <BreadcrumbItem href="/rotas/aguardando-aprovacao">
          Rotas
        </BreadcrumbItem>
        <BreadcrumbItem>Detalhe</BreadcrumbItem>
      </Breadcrumbs>

      <Spacer y={4} />

      <Outlet />
    </div>
  );
};

export default DetalheRotaLayout;

import { Button, Tooltip } from '@nextui-org/react';
import React, { FC, useEffect, useState } from 'react';
import { BsFillArchiveFill } from 'react-icons/bs';
import { FaListUl, FaMapMarkerAlt, FaRoute } from 'react-icons/fa';
import { FaBoxesPacking } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';

export interface SideMenuOption {
  icon: JSX.Element;
  name: string;
  path: string;
}

export interface SideMenuProps {
  isOpen: boolean;
}

const SideMenu: FC<SideMenuProps> = ({ isOpen }): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedMenuButton, setSelectedMenuButton] = useState<string>('');
  const menus: SideMenuOption[] = [
    {
      icon: <FaRoute className="h-6 w-6" />,
      name: 'Gerir rotas',
      path: '/rotas',
    },
    {
      icon: <FaBoxesPacking className="h-6 w-6" />,
      name: 'Conf. de pedidos',
      path: '/conferencia-de-pedidos',
    },
    {
      icon: <FaListUl className="h-6 w-6" />,
      name: 'Pedidos',
      path: '/gestao-de-pedidos',
    },
    {
      icon: <FaMapMarkerAlt className="h-6 w-6" />,
      name: 'Criação de Rotas',
      path: '/criar-rota',
    },
    {
      icon: <BsFillArchiveFill className="h-6 w-6" />,
      name: 'Cadastros',
      path: '/cadastros',
    },
  ];

  useEffect(() => {
    const menu = menus.find(({ path }) => pathname.includes(path));
    setSelectedMenuButton(menu?.name ?? '');
  }, [pathname]);

  return (
    <div
      className={`mt-8 w-full overflow-hidden transition-[max-width] duration-250 ${
        isOpen ? 'max-w-[200px]' : 'max-w-[64px]'
      }`}
    >
      {menus.map((menu) => (
        <Tooltip
          key={menu.name}
          showArrow
          placement="right"
          color="foreground"
          content={menu.name}
          isDisabled={isOpen}
          className="rounded px-4 py-2"
          offset={-12}
        >
          <Button
            variant={selectedMenuButton === menu.name ? 'solid' : 'ghost'}
            onPress={() => navigate(menu.path)}
            className="group flex w-full flex-row items-center justify-start gap-4 rounded-none border-none px-5 py-8"
          >
            <div
              className={`text-foreground-400 group-hover:text-foreground-800 ${
                selectedMenuButton === menu.name ? 'text-foreground-800' : ''
              }`}
            >
              {menu.icon}
            </div>

            <p
              className={`font-semibold text-foreground-400 transition-opacity group-hover:text-foreground-800 ${
                selectedMenuButton === menu.name ? 'text-foreground-800' : ''
              } ${isOpen ? 'opacity-100' : 'opacity-0'}`}
            >
              {menu.name}
            </p>
          </Button>
        </Tooltip>
      ))}
    </div>
  );
};

export default SideMenu;

import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from '@nextui-org/react';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react';
import { Controller } from 'react-hook-form';
import { FaPlusCircle } from 'react-icons/fa';
import { ITabelaPreco } from '../../interfaces/response/TabelaPreco/ITabelaPreco';
import { formatarData } from '../../util/format';
import { cepMask, moneyMask } from '../../util/mask';
import useRegisterLocationPriceModal from './hooks/useRegisterLocationPriceModal';

export interface RegisterLocationPriceModalHandle {
  openToEdit: (locationPriceAux: ITabelaPreco) => void;
  closeModal: () => void;
}

interface RegisterLocationPriceModalProps {
  onUpdate?: () => void;
}

const RegisterLocationPriceModal: ForwardRefRenderFunction<
  RegisterLocationPriceModalHandle,
  RegisterLocationPriceModalProps
> = ({ onUpdate }, ref): JSX.Element => {
  const {
    onOpen,
    isOpen,
    onOpenChange,
    theme,
    handleClose,
    handleSubmit,
    handleSubmitLocationPrice,
    handleOpenEdit,
    errors,
    register,
    isLoading,
    isValid,
    control,
    setValue,
    values,
    vehicleTypeOptions,
    locationPrice,
  } = useRegisterLocationPriceModal({ onUpdate });

  useImperativeHandle(ref, () => ({
    openToEdit: (locationPriceAux: ITabelaPreco) => {
      handleOpenEdit(locationPriceAux);
    },
    closeModal: () => {
      handleClose();
    },
  }));

  return (
    <>
      <Button
        onPress={onOpen}
        size="md"
        startContent={
          <div>
            <FaPlusCircle />
          </div>
        }
      >
        <p>Cadastrar tabela preço</p>
      </Button>

      <Modal
        isOpen={isOpen}
        size="4xl"
        onOpenChange={onOpenChange}
        className={`${theme}`}
        onClose={handleClose}
      >
        <ModalContent>
          <form onSubmit={handleSubmit(handleSubmitLocationPrice)}>
            <ModalHeader className="text-default-600">
              <p>{locationPrice ? 'Editar' : 'Cadastrar'} tabela preço</p>
            </ModalHeader>

            <ModalBody>
              {/* User information */}
              {locationPrice && (
                <Card
                  className="grid grid-cols-3 bg-foreground-100 p-4"
                  shadow="none"
                >
                  <div>
                    <p className="font-semibold">ID da Tabela preço:</p>
                    <p className="text-default-600">
                      {locationPrice.idTabelaPreco}
                    </p>
                  </div>

                  <div>
                    <p className="font-semibold">Criado por:</p>
                    <p className="text-default-600">
                      {locationPrice.nomeUsuarioCadastro} em{' '}
                      {formatarData(locationPrice.dataCadastro)}
                    </p>
                  </div>

                  <div
                    className={`${
                      locationPrice.nomeUsuarioAlteracao ? '' : 'hidden'
                    }`}
                  >
                    <p className="font-semibold">Alterado por:</p>
                    <p className="text-default-600">
                      {locationPrice.nomeUsuarioAlteracao} em{' '}
                      {formatarData(locationPrice.dataAlteracao)}{' '}
                    </p>
                  </div>
                </Card>
              )}

              {/* Inputs Grid */}
              <div className="grid grid-cols-2 gap-4">
                {/* Start Zip code */}
                <Input
                  size="md"
                  type="text"
                  label="CEP de início"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  errorMessage={errors.startZipCode?.message}
                  {...register('startZipCode')}
                  value={values.startZipCode}
                  onValueChange={(value) => {
                    const maskedValue = cepMask(value);
                    setValue('startZipCode', maskedValue);
                  }}
                />

                {/* End Zip code */}
                <Input
                  size="md"
                  type="text"
                  label="CEP final"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  errorMessage={errors.endZipCode?.message}
                  {...register('endZipCode')}
                  value={values.endZipCode}
                  onValueChange={(value) => {
                    const maskedValue = cepMask(value);
                    setValue('endZipCode', maskedValue);
                  }}
                />

                {/* Value */}
                <Input
                  size="md"
                  type="text"
                  label="Valor"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  startContent={
                    <div className="pointer-events-none flex items-center">
                      <span className="text-small text-default-400">R$</span>
                    </div>
                  }
                  errorMessage={errors.value?.message}
                  {...register('value')}
                  value={values.value}
                  onValueChange={(value) => {
                    const maskedValue = moneyMask(value, '', 2);
                    setValue('value', maskedValue);
                  }}
                />

                {/* Select */}
                <Controller
                  control={control}
                  name="vehicleType"
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="Tipo de Veículo"
                      variant="underlined"
                      size="md"
                      placeholder="Selecione um Tipo de veículo"
                      classNames={{
                        label: 'font-bold',
                        popoverContent: `${theme}`,
                      }}
                      selectedKeys={
                        values.vehicleType !== '' ? [values.vehicleType] : []
                      }
                      onBlur={field.onBlur}
                      className={`${theme}`}
                      onSelectionChange={field.onChange}
                    >
                      {vehicleTypeOptions.map(
                        ({ idTipoVeiculo, categoria }) => (
                          <SelectItem
                            key={idTipoVeiculo.toString()}
                            value={idTipoVeiculo.toString()}
                            textValue={categoria}
                          >
                            <p className="text-foreground-700">{categoria}</p>
                          </SelectItem>
                        )
                      )}
                    </Select>
                  )}
                />
              </div>
            </ModalBody>

            <ModalFooter>
              {/* Cancel Button */}
              <Button color="danger" onPress={handleClose}>
                Cancelar
              </Button>

              {/* Save Button */}
              <Button type="submit" isLoading={isLoading} isDisabled={!isValid}>
                <p>{locationPrice ? 'Alterar' : 'Cadastrar'}</p>
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default forwardRef(RegisterLocationPriceModal);

import { SortDescriptor } from '@nextui-org/react';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { IDadosPedido } from '../../../../../../interfaces/response/Pedido/IDadosPedido';
import { formatarData } from '../../../../../../util/format';
import {
  formatarMedidaGenerico,
  formatarMoeda,
} from '../../../../../../util/mask';

type Column = {
  key: any;
  name: string;
};

export interface UseOrderListTableProps {
  orders: IDadosPedido[];
}

export interface UseOrderListTable {
  cellData: (order: IDadosPedido, columnKey: any) => number | string;
  columns: Column[];
  setSortDescriptor: Dispatch<SetStateAction<SortDescriptor>>;
  sortDescriptor: SortDescriptor;
  sortedOrders: IDadosPedido[];
}

const useOrderListTable = ({
  orders,
}: UseOrderListTableProps): UseOrderListTable => {
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'Ordem de entrega',
    direction: 'descending',
  });

  const columns: Column[] = [
    {
      key: 'ID',
      name: 'ID',
    },
    {
      key: 'DT LIMITE ENTREGA',
      name: 'DT LIMITE ENTREGA',
    },
    {
      key: 'UF INÍCIO',
      name: 'UF INÍCIO',
    },
    {
      key: 'UF FINAL',
      name: 'UF FINAL',
    },
    {
      key: 'MODAL',
      name: 'MODAL',
    },
    {
      key: 'NR CT',
      name: 'NR CT',
    },
    {
      key: 'SERIE',
      name: 'SERIE',
    },
    {
      key: 'TIPO SERVIÇO',
      name: 'TIPO SERVIÇO',
    },
    {
      key: 'VALOR CARGA',
      name: 'VALOR CARGA',
    },
    {
      key: 'PESO REAL',
      name: 'PESO REAL',
    },
    {
      key: 'PESO CUBADO',
      name: 'PESO CUBADO',
    },
    {
      key: 'PESO TAXADO',
      name: 'PESO TAXADO',
    },
    {
      key: 'FRETE PESO',
      name: 'FRETE PESO',
    },
    {
      key: 'VOLUMES',
      name: 'VOLUMES',
    },
    {
      key: 'DATA EMISSÃO',
      name: 'DATA EMISSÃO',
    },
    {
      key: 'DATA IMPORTAÇÃO',
      name: 'DATA IMPORTAÇÃO',
    },
  ];

  const cellData = (order: IDadosPedido, columnKey: any): number | string => {
    switch (columnKey) {
      case 'ID':
        return order.pedido.idCte;
      case 'DT LIMITE ENTREGA':
        return formatarData(order.pedido.dtLimiteEntrega, true, 'normal') || '';
      case 'UF INÍCIO':
        return `${order.pedido.ufIni} - ${order.pedido.municipioIni}`;
      case 'UF FINAL':
        return `${order.pedido.ufFim} - ${order.pedido.municipioFim}`;
      case 'MODAL':
        return order.pedido.modal.toUpperCase();
      case 'NR CT':
        return order.pedido.nCT;
      case 'SERIE':
        return order.pedido.serie;
      case 'TIPO SERVIÇO':
        return order.pedido.tpServ.toUpperCase();
      case 'VALOR CARGA':
        return formatarMoeda(order.pedido.vlCarga);
      case 'PESO REAL':
        return formatarMedidaGenerico(
          order.pedido.pesoReal,
          order.pedido.unidadeMedidaPesoReal
        );
      case 'PESO CUBADO':
        return formatarMedidaGenerico(
          order.pedido.pesoCubado,
          order.pedido.unidadeMedidaPesoCubado
        );
      case 'PESO TAXADO':
        return formatarMedidaGenerico(
          order.pedido.pesoTaxado,
          order.pedido.unidadeMedidaPesoTaxado
        );
      case 'FRETE PESO':
        return formatarMoeda(order.pedido.fretePeso);
      case 'VOLUMES':
        return order.pedido.volumes;
      case 'DATA EMISSÃO':
        return formatarData(order.pedido.dtEmissao, false, 'normal') || '';
      case 'DATA IMPORTAÇÃO':
        return formatarData(order.pedido.dataInclusao, false, 'normal') || '';
      default:
        return '';
    }
  };

  const sortedOrders = useMemo(() => {
    return [...orders].sort((a: IDadosPedido, b: IDadosPedido) => {
      const first = cellData(a, sortDescriptor.column as any) as number;
      const second = cellData(b, sortDescriptor.column as any) as number;

      let cmp = 0;
      if (first < second) cmp = -1;
      if (first > second) cmp = 1;

      return sortDescriptor.direction === 'ascending' ? -cmp : cmp;
    });
  }, [sortDescriptor, orders]);

  return {
    cellData,
    columns,
    setSortDescriptor,
    sortDescriptor,
    sortedOrders,
  };
};

export default useOrderListTable;

import { Divider, Spacer } from '@nextui-org/react';
import React from 'react';
import { Outlet } from 'react-router-dom';

const CheckOrdersLayout = (): JSX.Element => {
  return (
    <div className="flex grow flex-col">
      {/* Title */}
      <h1 className="text-2xl font-bold text-foreground-800">
        Conferência de pedidos
      </h1>

      <Divider className="my-4" />

      <Spacer y={4} />

      <Outlet />
    </div>
  );
};

export default CheckOrdersLayout;

import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUsuario } from '../../../../../redux/features/generalData/generalDataSelectors';

export interface IUseGerirRotasLayout {
  mapContainerRef: RefObject<HTMLDivElement>;
  map: google.maps.Map | undefined;
}

const useCreateMap = (): IUseGerirRotasLayout => {
  const [map, setMap] = useState<any>();
  const user = useSelector(selectUsuario);
  const mapContainerRef = useRef<HTMLDivElement>(null);

  const mapOptions = useMemo(
    () => ({
      mapId: process.env.REACT_APP_MAP_ID,
      center: {
        lat: parseFloat(user?.defaultWarehouse?.endereco.lat || ''),
        lng: parseFloat(user?.defaultWarehouse?.endereco.lng || ''),
      },
      zoom: 10,
      disableDefaultUI: true,
    }),
    [user]
  );

  useEffect(() => {
    if (!mapContainerRef.current) return;

    setMap(new window.google.maps.Map(mapContainerRef.current, mapOptions));
  }, [mapContainerRef, mapOptions]);

  return {
    map,
    mapContainerRef,
  };
};

export default useCreateMap;

import {
  CircularProgress,
  Divider,
  Image,
  Navbar as NavigationBar,
  NavbarContent,
} from '@nextui-org/react';
import React, { FC, useRef } from 'react';
import { User } from '../../interfaces/IUsuario';
import ConfirmationModal, {
  ConfirmationModalHandle,
} from '../ConfirmationModal/ConfirmationModal';
import ThemeSwitch from '../ThemeSwitch/ThemeSwitch';
import ToggleMenuButton from '../ToggleMenuButton/ToggleMenuButton';
import UserDropdown from '../UserDropdown/UserDropdown';
import WarehouseDropdown from '../WarehouseDropdown/WarehouseDropdown';

interface NavbarProps {
  onMenuToggle: (isOpen: boolean) => void;
  user: User | undefined;
}

const Navbar: FC<NavbarProps> = ({ onMenuToggle, user }): JSX.Element => {
  const confirmModalRef = useRef<ConfirmationModalHandle>(null);

  return (
    <NavigationBar
      classNames={{
        wrapper: ['max-w-none', 'content-between', `bg-foreground-50`],
      }}
    >
      <ConfirmationModal
        ref={confirmModalRef}
        cancelText="Não"
        confirmText="Sim"
        header="Realmente deseja trocar de deposito?"
        text="Ao trocar de deposito, os dados serão novamente carregados e você perderá o trabalho atual caso nao tenha salvo, deseja trocar o deposito mesmo assim?"
      />
      <NavbarContent className="ml-[-12px] gap-4">
        {/* Menu Toggle */}
        <ToggleMenuButton onMenuToggle={onMenuToggle} />

        {/* Logo  */}
        <div
          className="rounded-md bg-foreground-500 px-3 py-1"
          style={{
            width: 200,
            height: 45,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {user && user.customerAccess.customer.logo ? (
            <Image
              style={{
                width: '100%',
                height: '100%',
              }}
              className="rounded-none"
              alt="Company Logo"
              src={user.customerAccess.customer.logo}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: 197,
                height: 44,
              }}
            >
              <CircularProgress color="default" size="md" />
            </div>
          )}
        </div>

        <Divider orientation="vertical" className="h-8 w-[2px]" />

        {/* App Title */}
        <h3 className="whitespace-nowrap font-bold text-foreground-800">
          Roteirizador de entregas
        </h3>
      </NavbarContent>

      <NavbarContent justify="end">
        {/* Warehouse Dropdown */}
        <WarehouseDropdown confirmModalRef={confirmModalRef} />

        {/* Theme Switcher */}
        <ThemeSwitch />

        {/* User Menu */}
        <UserDropdown />
      </NavbarContent>
    </NavigationBar>
  );
};

export default Navbar;

import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react';
import React from 'react';
import { BiSolidUser } from 'react-icons/bi';
import { MdLogout } from 'react-icons/md';
import MenuHeader from './components/MenuHeader/MenuHeader';
import useUserDropdown from './hooks/useUserDropdown';

const UserDropdown = (): JSX.Element => {
  const { theme, user, navigate, handleLogOut } = useUserDropdown();

  return (
    <Dropdown placement="bottom-end" className={theme}>
      <DropdownTrigger>
        <Avatar isBordered as="button" name={user?.name} />
      </DropdownTrigger>

      <DropdownMenu
        aria-label="Ações de usuário"
        variant="flat"
        topContent={<MenuHeader user={user} />}
      >
        <DropdownItem
          key="settings"
          className="p-4 text-foreground-700"
          onPress={() => navigate('/minha-conta')}
          startContent={<BiSolidUser />}
        >
          Minha conta
        </DropdownItem>

        <DropdownItem
          key="logout"
          color="danger"
          className="p-4 text-foreground-700"
          onPress={handleLogOut}
          startContent={<MdLogout />}
        >
          Sair
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default UserDropdown;

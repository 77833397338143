import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AuthSchemaAPI } from '../../../config/api';
import { IBadRequestResponse } from '../../../interfaces/IBadRequestResponse';

export const getGeneralData = createAsyncThunk(
  'generalData/getGeneralData',
  async () => {
    return '';
  }
);

export const postLogin = createAsyncThunk(
  'generalData/postLogin',
  async (
    args: {
      email: string;
      password: string;
    },
    { rejectWithValue, fulfillWithValue }
  ): Promise<AxiosError<IBadRequestResponse> | any> => {
    const { email, password } = args;

    try {
      const response = await AuthSchemaAPI.post(
        'api/v1/user/login/roteirizador',
        {
          key: email,
          password,
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        }
      );

      return fulfillWithValue(response.data.user);
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;
      return rejectWithValue(error);
    }
  }
);

export const refreshToken = createAsyncThunk(
  'generalData/refreshToken',
  async (
    args: {
      email: string;
    },
    { rejectWithValue, fulfillWithValue }
  ): Promise<AxiosError<IBadRequestResponse> | any> => {
    const { email } = args;

    try {
      const response = await AuthSchemaAPI.post(
        'api/v1/user/roteirizador/refresh/token',
        {
          key: email,
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        }
      );

      return fulfillWithValue(response.data);
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;
      return rejectWithValue(error);
    }
  }
);

import { zodResolver } from '@hookform/resolvers/zod';
import { useDisclosure } from '@nextui-org/react';
import { useContext, useState } from 'react';
import {
  FieldErrors,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { z } from 'zod';
import {
  Theme,
  ThemeContext,
} from '../../../contexts/ThemeContext/ThemeContext';
import { atualizaSenhaUsuario } from '../../../redux/features/clientsData/clientsDataThunk';
import { selectUsuario } from '../../../redux/features/generalData/generalDataSelectors';
import { logIn } from '../../../redux/features/generalData/generalDataSlice';
import { useAsyncDispatch } from '../../../redux/store';

const changePasswordFormSchema = z
  .object({
    currentPassword: z
      .string()
      .min(1, 'A senha atual é obrigatória')
      .min(8, 'Senha deve ter no mínimo 8 caractéres'),
    newPassword: z
      .string()
      .min(1, 'Nova senha é obrigatória')
      .min(8, 'Senha deve ter no mínimo 8 caractéres'),
    newPasswordConfirmation: z.string().min(1, 'Confirmação é obrigatória'),
  })
  .refine(
    ({ newPassword, newPasswordConfirmation }) =>
      newPassword === newPasswordConfirmation,
    {
      message: 'As senhas não conferem',
      path: ['newPasswordConfirmation'],
    }
  )
  .refine(
    ({ currentPassword, newPassword }) => currentPassword !== newPassword,
    {
      message: 'A nova senha não pode ser igual a anterior',
      path: ['newPassword'],
    }
  );

type ChangePasswordFormSchema = z.infer<typeof changePasswordFormSchema>;

export interface UseChangePasswordModal {
  errors: FieldErrors<ChangePasswordFormSchema>;
  handleChangePassword: (data: ChangePasswordFormSchema) => Promise<void>;
  handleClose: () => void;
  handleSubmit: UseFormHandleSubmit<ChangePasswordFormSchema>;
  isLoading: boolean;
  isOpen: boolean;
  isValid: boolean;
  onOpen: () => void;
  onOpenChange: () => void;
  register: UseFormRegister<ChangePasswordFormSchema>;
  theme: Theme;
}

const useChangePasswordModal = (): UseChangePasswordModal => {
  const dispatch = useAsyncDispatch();
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const { theme } = useContext(ThemeContext);
  const user = useSelector(selectUsuario);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { register, handleSubmit, clearErrors, formState, reset } =
    useForm<ChangePasswordFormSchema>({
      resolver: zodResolver(changePasswordFormSchema),
      mode: 'onTouched',
      reValidateMode: 'onChange',
    });

  const { errors, isValid } = formState;

  const handleClose = (): void => {
    onClose();
    clearErrors();
    reset();
  };

  const handleChangePassword = async (
    data: ChangePasswordFormSchema
  ): Promise<void> => {
    if (!user) return;
    const { _Id: userId, accessToken } = user;
    const { currentPassword, newPassword } = data;

    setIsLoading(true);
    await dispatch(
      atualizaSenhaUsuario({
        userId,
        productId: 1,
        currentPassword,
        newPassword,
        token: accessToken.token,
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Senha alterada com sucesso');
        dispatch(logIn());
        handleClose();
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao realizar a troca da senha, por favor tente mais tarde.'
        );
      })
      .finally(() => setIsLoading(false));
  };

  return {
    errors,
    handleChangePassword,
    handleClose,
    handleSubmit,
    isLoading,
    isOpen,
    isValid,
    onOpen,
    onOpenChange,
    register,
    theme,
  };
};

export default useChangePasswordModal;

import { SortDescriptor } from '@nextui-org/react';
import {
  Dispatch,
  Key,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { IMotorista } from '../../../../../interfaces/response/Motorista/IMotorista';
import { formatarData } from '../../../../../util/format';
import { mascararDocumento } from '../../../../../util/mask';

export type Header =
  | 'idMotorista'
  | 'nome'
  | 'email'
  | 'cpf'
  | 'ativo'
  | 'dataVencimentoCNH'
  | 'dataVencimentoPesquisa'
  | 'dataCadastro'
  | 'idTipoMotorista';

type Column = {
  key: Header;
  name: string;
};

export interface UseDriverListTableProps {
  drivers: IMotorista[];
  onDriverClick?: (driver: IMotorista) => void;
}

export interface UseDriverListTable {
  handleRowClick: (key: Key) => void;
  sortDescriptor: SortDescriptor;
  setSortDescriptor: Dispatch<SetStateAction<SortDescriptor>>;
  columns: Column[];
  sortedDrivers: IMotorista[];
  cellData: (driver: IMotorista, columnKey: Header) => number | string;
}

const useDriverListTable = ({
  drivers,
  onDriverClick,
}: UseDriverListTableProps): UseDriverListTable => {
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'idMotorista',
    direction: 'descending',
  });

  const sortedDrivers = useMemo(() => {
    return [...drivers].sort((a: IMotorista, b: IMotorista) => {
      const first = a[sortDescriptor.column as keyof IMotorista] as number;
      const second = b[sortDescriptor.column as keyof IMotorista] as number;

      let cmp = 0;
      if (first < second) cmp = -1;
      if (first > second) cmp = 1;

      return sortDescriptor.direction === 'ascending' ? -cmp : cmp;
    });
  }, [sortDescriptor, drivers]);

  const columns: Column[] = [
    {
      key: 'idMotorista',
      name: 'ID',
    },
    {
      key: 'nome',
      name: 'NOME',
    },
    {
      key: 'email',
      name: 'EMAIL',
    },
    {
      key: 'cpf',
      name: 'CPF',
    },
    {
      key: 'ativo',
      name: 'ATIVO',
    },
    {
      key: 'dataVencimentoCNH',
      name: 'DATA VENCIMENTO DA CNH',
    },
    {
      key: 'dataVencimentoPesquisa',
      name: 'DATA VENCIMENTO PESQUISA',
    },
    {
      key: 'dataCadastro',
      name: 'DATA DE CADASTRO',
    },
    {
      key: 'idTipoMotorista',
      name: 'TIPO DE MOTORISTA',
    },
  ];

  const cellData = useCallback(
    (driver: IMotorista, columnKey: Header): number | string => {
      switch (columnKey) {
        case 'idMotorista':
          return driver.idMotorista;
        case 'nome':
          return driver.nome;
        case 'email':
          return driver.email;
        case 'cpf':
          return mascararDocumento(1, driver.cpf);
        case 'ativo':
          return driver.ativo ? 'SIM' : 'NÃO';
        case 'dataVencimentoCNH':
          return formatarData(driver.dataVencimentoCNH, true) ?? '';
        case 'dataVencimentoPesquisa':
          return formatarData(driver.dataVencimentoPesquisa, true) ?? '';
        case 'dataCadastro':
          return formatarData(driver.dataCadastro) ?? '';
        case 'idTipoMotorista':
          return driver.idTipoMotorista === 1 ? 'Frota' : 'Agregado';
        default:
          return '';
      }
    },
    []
  );

  const handleRowClick = (key: Key): void => {
    const driver = drivers.find(
      (driverAux) => driverAux.idMotorista.toString() === key
    );
    if (onDriverClick && driver) onDriverClick(driver);
  };

  return {
    cellData,
    columns,
    handleRowClick,
    setSortDescriptor,
    sortDescriptor,
    sortedDrivers,
  };
};

export default useDriverListTable;

import React, { FC } from 'react';
import { User } from '../../../../interfaces/IUsuario';

interface MenuProps {
  user: User | undefined;
}

const MenuHeader: FC<MenuProps> = ({ user }): JSX.Element => (
  <div className="rounded-lg bg-foreground-200 px-4 py-2">
    <p className="mb-1 font-medium text-foreground-600">Logado como:</p>
    <p className="font-semibold text-foreground-600">{user?.name}</p>
    <p className="font-semibold text-foreground-600">{user?.maskedEmail}</p>
  </div>
);

export default MenuHeader;

import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import React from 'react';
import { IMotorista } from '../../../../interfaces/response/Motorista/IMotorista';
import useDriverListTable, { Header } from './hooks/useDriverListTable';

export interface IDriverListTable {
  drivers: IMotorista[];
  onDriverClick?: (driver: IMotorista) => void;
}

const DriverListTable: React.FC<IDriverListTable> = ({
  drivers,
  onDriverClick,
}): JSX.Element => {
  const {
    handleRowClick,
    sortDescriptor,
    setSortDescriptor,
    columns,
    sortedDrivers,
    cellData,
  } = useDriverListTable({ drivers, onDriverClick });

  return (
    <Table
      aria-label="Tabela de motoristas"
      selectionMode="single"
      onRowAction={handleRowClick}
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
    >
      <TableHeader columns={columns}>
        {({ key, name }) => (
          <TableColumn key={key} allowsSorting>
            {name}
          </TableColumn>
        )}
      </TableHeader>

      <TableBody
        items={sortedDrivers}
        emptyContent="Nenhum motorista no momento"
      >
        {(item) => (
          <TableRow key={item.idMotorista}>
            {(columnKey) => (
              <TableCell>
                <p className="text-foreground-600">
                  {cellData(item, columnKey as Header)}
                </p>
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default DriverListTable;

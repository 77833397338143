import { Divider, Spacer } from '@nextui-org/react';
import React from 'react';
import { Outlet } from 'react-router-dom';

const DetalheMotoristaLayout = (): JSX.Element => {
  return (
    <div className="flex grow flex-col">
      {/* Title */}
      <h1 className="text-2xl font-bold text-foreground-800">
        Detalhes Motorista
      </h1>

      <Divider className="my-4" />

      <Spacer y={4} />

      <Outlet />
    </div>
  );
};

export default DetalheMotoristaLayout;

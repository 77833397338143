import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { ICadastraVeiculoMotoristaRequest } from '../../../interfaces/request/VeiculoMotorista/ICadastraVeiculoMotoristaRequest';
import { IMotorista } from '../../../interfaces/response/Motorista/IMotorista';
import { IVeiculo } from '../../../interfaces/response/Veiculo/IVeiculo';
import { IVeiculoMotorista } from '../../../interfaces/response/VeiculoMotorista/IVeiculoMotorista';
import {
  AlteraVeiculoPadrao,
  DeletarVeiculoMotorista,
  ObterMotorista,
  ObterVeiculo,
  ObterVeiculosMotorista,
} from '../../../redux/features/clientsData/clientsDataThunk';
import { useAsyncDispatch } from '../../../redux/store';

export interface IMessageProps {
  mensagem: string;
  show: boolean;
  type: 'success' | 'error';
}

export interface IProps {
  driver: IMotorista | undefined;
  vehicleOptions: IVeiculo[];
  driverVehicles: IVeiculoMotorista[];
  loadingDriver: boolean;
  loadingVehicles: boolean;
  handleGetRemainingVehicles: () => Promise<void>;
  handleGetDriver: () => Promise<void>;
  setVehicleAsDefault: (driverVehicle: IVeiculoMotorista) => Promise<void>;
  removeVehicle: (driverVehicle: IVeiculoMotorista) => Promise<void>;
}

const useDetalheMotorista = (): IProps => {
  const [driver, setDriver] = useState<IMotorista | undefined>(undefined);
  const [vehicleOptions, setVehicleOptions] = useState<IVeiculo[]>([]);
  const [driverVehicles, setDriverVehicles] = useState<IVeiculoMotorista[]>([]);
  const [loadingDriver, setLoadingDriver] = useState<boolean>(false);
  const [loadingVehicles, setLoadingVehicles] = useState<boolean>(false);
  const dispatch = useAsyncDispatch();
  const navigate = useNavigate();
  const { id: driverId } = useParams();

  const handleGetDriver = async (): Promise<void> => {
    setLoadingDriver(true);

    if (!driverId) return;

    await dispatch(
      ObterMotorista({
        idMotorista: parseInt(driverId, 10),
      })
    )
      .unwrap()
      .then((response) => setDriver(response.motorista))
      .catch(() => {
        toast.error('Motorista não encontrado, por favor tente mais tarde');
        navigate('/cadastros/motorista');
      })
      .finally(() => setLoadingDriver(false));
  };

  const handleGetRemainingVehicles = async (): Promise<void> => {
    if (!driverId) return;

    setLoadingVehicles(true);
    let auxDriverVehicles: IVeiculoMotorista[] = [];
    let error;

    await dispatch(
      ObterVeiculosMotorista({
        idMotorista: parseInt(driverId, 10),
      })
    )
      .unwrap()
      .then((response) => {
        auxDriverVehicles = response.veiculosMotorista;
        setDriverVehicles(auxDriverVehicles);
      })
      .catch((err) => {
        error = err;
        toast.error(
          'Veículos do motorista não encontrados, por favor tente mais tarde'
        );
      });

    if (error) return;

    await dispatch(ObterVeiculo())
      .unwrap()
      .then((response) => {
        const unfilteredVehicles: IVeiculo[] = response.veiculos;
        const filteredVehicles = unfilteredVehicles.filter((auxVehicle) => {
          return !auxDriverVehicles.some((driverVehicle) => {
            return auxVehicle.idVeiculo === driverVehicle.idVeiculo;
          });
        });

        setVehicleOptions(filteredVehicles);
      })
      .catch(() => {
        toast.error('Erro ao obter veículos, por favor tente mais tarde');
      })
      .finally(() => setLoadingVehicles(false));
  };

  const setVehicleAsDefault = async (
    driverVehicle: IVeiculoMotorista
  ): Promise<void> => {
    setLoadingVehicles(true);

    const { idMotorista, idVeiculo } = driverVehicle;

    const body: ICadastraVeiculoMotoristaRequest = {
      idMotorista,
      idVeiculo,
      veiculoPadrao: true,
    };

    await dispatch(
      AlteraVeiculoPadrao({
        body,
      })
    )
      .unwrap()
      .then(() => {
        handleGetRemainingVehicles();
        toast.success('Alteração realizada com sucesso');
      })
      .catch(() =>
        toast.error(
          'Ocorreu um erro ao alterar o veiculo padrão do motorista, por favor tente mais tarde'
        )
      )
      .finally(() => setLoadingVehicles(false));
  };

  const removeVehicle = async (
    driverVehicle: IVeiculoMotorista
  ): Promise<void> => {
    setLoadingVehicles(true);

    const { idMotorista, idVeiculo } = driverVehicle;

    await dispatch(
      DeletarVeiculoMotorista({
        idMotorista,
        idVeiculo,
      })
    )
      .unwrap()
      .then(() => {
        handleGetRemainingVehicles();
        toast.success('Remoção realizada com sucesso');
      })
      .catch(() =>
        toast.error(
          'Ocorreu um erro ao remover o veiculo do motorista, por favor tente mais tarde'
        )
      )
      .finally(() => setLoadingVehicles(false));
  };

  useEffect(() => {
    handleGetDriver();
    handleGetRemainingVehicles();
  }, []);

  return {
    driver,
    vehicleOptions,
    driverVehicles,
    loadingDriver,
    loadingVehicles,
    handleGetDriver,
    handleGetRemainingVehicles,
    removeVehicle,
    setVehicleAsDefault,
  };
};

export default useDetalheMotorista;

import { Input } from '@nextui-org/react';
import React from 'react';
import { IDadosPedido } from '../../../../../../../interfaces/response/Pedido/IDadosPedido';
import { formatarCEP, mascararDocumento } from '../../../../../../../util/mask';

interface ITabProps {
  pedido: IDadosPedido | undefined;
}

const TabDetalheEmitente = ({ pedido }: ITabProps): JSX.Element => {
  return (
    <div className="flex w-full flex-row gap-2">
      <div className="flex w-full flex-col gap-2">
        {/* Linha 1: Nome e Email */}
        <div className="flex w-full flex-wrap gap-2">
          <div className="flex-1">
            <Input
              size="md"
              label="Nome"
              value={pedido?.emitente.nome}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="md"
              label="Nome Fantasia"
              value={pedido?.emitente.nomeFantasia}
              disabled
            />
          </div>
        </div>

        {/* Linha 2: CNPJ e CPF */}
        <div className="flex w-full flex-wrap gap-2">
          <div className="flex-1">
            <Input
              size="md"
              label="CNPJ"
              value={mascararDocumento(2, pedido?.emitente.cnpj || '')}
              disabled
            />
          </div>
        </div>

        {/* Linha 3: Logradouro */}
        <div className="flex w-full flex-wrap gap-2">
          <div className="flex-1">
            <Input
              size="md"
              label="Logradouro"
              value={pedido?.emitente.logradouro}
              disabled
            />
          </div>
        </div>

        {/* Linha 4: Numero, Complemento, Bairro */}
        <div className="flex w-full flex-wrap gap-2">
          <div className="flex-1">
            <Input
              size="md"
              label="Número"
              value={pedido?.emitente.numero}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="md"
              label="Complemento"
              value={pedido?.emitente.complemento}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="md"
              label="Bairro"
              value={pedido?.emitente.bairro}
              disabled
            />
          </div>
        </div>

        {/* Linha 5: Cidade, Estado CEP */}
        <div className="flex w-full flex-wrap gap-2">
          <div className="flex-1">
            <Input
              size="md"
              label="Cidade"
              value={pedido?.emitente.cidade}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="md"
              label="Estado"
              value={pedido?.emitente.estado}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="md"
              label="CEP"
              value={formatarCEP(pedido?.emitente.cep || '')}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabDetalheEmitente;

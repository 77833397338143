import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import SideMenu from '../../components/SideMenu/SideMenu';
import {
  setAuthSchemaAPIToken,
  setCustomerAPIToken,
  setMiddlewareAPIToken,
  setOrderAPIToken,
  setRoteirizadorAPIToken,
  setRoutesAPIToken,
} from '../../config/api';
import { MouseActivityProvider } from '../../providers/Inactivity/MouseActivityProvider';
import { selectUsuario } from '../../redux/features/generalData/generalDataSelectors';
import useApplicationLayout from './hooks/useApplicationLayout';

const ApplicationLayout = (): JSX.Element => {
  const { menuColapsed, setMenuColapsed } = useApplicationLayout();
  const location = useLocation();
  const user = useSelector(selectUsuario);

  // Set token if it exists
  setAuthSchemaAPIToken(user?.accessToken?.token ?? '');
  setRoteirizadorAPIToken(user?.accessToken?.token ?? '');
  setMiddlewareAPIToken(user?.accessToken?.token ?? '');
  setCustomerAPIToken(user?.accessToken?.token ?? '');
  setOrderAPIToken(user?.accessToken?.token ?? '');
  setRoutesAPIToken(user?.accessToken?.token ?? '');
  return (
    <MouseActivityProvider>
      <div id="cointainer" className="flex h-full flex-col bg-foreground-50">
        <Navbar user={user} onMenuToggle={setMenuColapsed} />

        <div className="flex h-full max-h-[calc(100%-64px)] bg-foreground-50">
          <SideMenu isOpen={!menuColapsed} />

          <div
            className={`w-full overflow-auto rounded-tl-2xl border-l border-t border-foreground-300 bg-background ${
              location.pathname.includes('criar-rota') ||
              location.pathname.includes('rotas')
                ? ''
                : 'px-12 pt-8'
            }`}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </MouseActivityProvider>
  );
};

export default ApplicationLayout;

import { Divider, Spacer, Tab, Tabs } from '@nextui-org/react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import useCadastrosLayout from './hooks/useCadastrosLayout';

const CadastrosLayout = (): JSX.Element => {
  const { tabOptions, pathname, handleRedirectTo } = useCadastrosLayout();

  return (
    <div className="flex grow flex-col">
      {/* Title */}
      <h1 className="text-2xl font-bold text-foreground-800">Cadastros</h1>

      <Divider className="my-4" />

      <Tabs
        variant="underlined"
        selectedKey={pathname}
        onSelectionChange={(key) => handleRedirectTo(key.toString())}
      >
        {tabOptions.map(({ name, path }) => (
          <Tab key={path} title={name} />
        ))}
      </Tabs>

      <Spacer y={4} />

      <Outlet />
    </div>
  );
};

export default CadastrosLayout;

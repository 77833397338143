import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from '@nextui-org/react';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react';
import { Controller } from 'react-hook-form';
import { FaPlusCircle } from 'react-icons/fa';
import { IVeiculo } from '../../interfaces/response/Veiculo/IVeiculo';
import { formatarData } from '../../util/format';
import { moneyMask, plateMask } from '../../util/mask';
import DatepickerInput from '../DatepickerInput/DatepickerInput';
import useRegisterVehicleModal from './hooks/useRegisterVehicleModal';

export interface RegisterVehicleModalHandle {
  openToEdit: (vehicleAux: IVeiculo) => void;
  closeModal: () => void;
}

interface RegisterVehicleModalProps {
  onUpdate?: () => void;
}

const RegisterVehicleModal: ForwardRefRenderFunction<
  RegisterVehicleModalHandle,
  RegisterVehicleModalProps
> = ({ onUpdate }, ref): JSX.Element => {
  const {
    onOpen,
    isOpen,
    onOpenChange,
    theme,
    handleClose,
    handleSubmit,
    handleSubmitVehicle,
    handleOpenEdit,
    errors,
    register,
    isLoading,
    isValid,
    control,
    setValue,
    values,
    vehicleTypeOptions,
    vehicle,
    handleVehicleSelected,
  } = useRegisterVehicleModal({ onUpdate });

  useImperativeHandle(ref, () => ({
    openToEdit: (vehicleAux: IVeiculo) => {
      handleOpenEdit(vehicleAux);
    },
    closeModal: () => {
      handleClose();
    },
  }));

  return (
    <>
      <Button
        onPress={onOpen}
        size="md"
        startContent={
          <div>
            <FaPlusCircle />
          </div>
        }
      >
        <p>Cadastrar veículo</p>
      </Button>

      <Modal
        isOpen={isOpen}
        size="4xl"
        onOpenChange={onOpenChange}
        className={`${theme} overflow-visible`}
        onClose={handleClose}
      >
        <ModalContent>
          <form onSubmit={handleSubmit(handleSubmitVehicle)}>
            <ModalHeader className="text-default-600">
              <p>{vehicle ? 'Editar' : 'Cadastrar'} veículo</p>
            </ModalHeader>

            <ModalBody>
              {/* User information */}
              {vehicle && (
                <Card
                  className="grid grid-cols-3 bg-foreground-100 p-4"
                  shadow="none"
                >
                  <div>
                    <p className="font-semibold">ID do veículo:</p>
                    <p className="text-default-600">{vehicle.idVeiculo}</p>
                  </div>

                  <div>
                    <p className="font-semibold">Criado por:</p>
                    <p className="text-default-600">
                      {vehicle.nomeUsuarioCadastro} em{' '}
                      {formatarData(vehicle.dataCadastro)}
                    </p>
                  </div>

                  <div
                    className={`${
                      vehicle.nomeUsuarioAlteracao ? '' : 'hidden'
                    }`}
                  >
                    <p className="font-semibold">Alterado por:</p>
                    <p className="text-default-600">
                      {vehicle.nomeUsuarioAlteracao} em{' '}
                      {formatarData(vehicle.dataAlteracao)}{' '}
                    </p>
                  </div>
                </Card>
              )}

              {/* Inputs Grid */}
              <div className="grid grid-cols-3 gap-4">
                {/* Plate */}
                <Input
                  size="md"
                  type="text"
                  label="Placa"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  errorMessage={errors.plate?.message}
                  {...register('plate')}
                  value={values.plate}
                  onValueChange={(value) => {
                    const maskedValue = plateMask(value);
                    setValue('plate', maskedValue);
                  }}
                />

                {/* DOC */}
                <Input
                  size="md"
                  type="text"
                  label="DOC"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  errorMessage={errors.doc?.message}
                  {...register('doc')}
                  value={values.doc}
                />

                {/* ANTT */}
                <Input
                  size="md"
                  type="text"
                  label="ANTT"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  errorMessage={errors.antt?.message}
                  {...register('antt')}
                  value={values.antt}
                />

                {/* Vehicle Type */}
                <Controller
                  control={control}
                  name="vehicleType"
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="Tipo de Veículo"
                      variant="underlined"
                      size="md"
                      placeholder="Selecione um Tipo de veículo"
                      classNames={{
                        label: 'font-bold',
                        popoverContent: `${theme}`,
                      }}
                      selectedKeys={
                        values.vehicleType !== '' ? [values.vehicleType] : []
                      }
                      onBlur={field.onBlur}
                      className={`${theme}`}
                      onSelectionChange={(keys) => {
                        field.onChange(keys);
                        handleVehicleSelected(keys);
                      }}
                    >
                      {vehicleTypeOptions.map(
                        ({
                          idTipoVeiculo,
                          categoria,
                          cubagem_Altura: height,
                          cubagem_Diametro: diameter,
                          cubagem_Largura: width,
                        }) => (
                          <SelectItem
                            key={idTipoVeiculo.toString()}
                            value={idTipoVeiculo.toString()}
                            textValue={categoria}
                          >
                            <p className="text-foreground-700">
                              <span className="font-semibold">{categoria}</span>{' '}
                              - {height}x{diameter}x{width}
                            </p>
                          </SelectItem>
                        )
                      )}
                    </Select>
                  )}
                />

                {/* Value */}
                <Input
                  size="md"
                  type="text"
                  label="Valor PGR"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  startContent={
                    <div className="pointer-events-none flex items-center">
                      <span className="text-small text-default-400">R$</span>
                    </div>
                  }
                  errorMessage={errors.value?.message}
                  {...register('value')}
                  value={values.value}
                  onValueChange={(value) => {
                    const maskedValue = moneyMask(value, '', 2);
                    setValue('value', maskedValue);
                  }}
                />

                {/* Weight */}
                <Input
                  size="md"
                  type="text"
                  label="Peso"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  endContent={<p className="text-sm font-medium">kg</p>}
                  errorMessage={errors.weight?.message}
                  {...register('weight')}
                  value={values.weight}
                  onValueChange={(value) => {
                    const maskedValue = moneyMask(value, '', 2);
                    setValue('weight', maskedValue);
                  }}
                />

                {/* Diameter */}
                <Input
                  size="md"
                  type="text"
                  label="Diâmetro"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  endContent={<p className="text-sm font-medium">m</p>}
                  errorMessage={errors.diameter?.message}
                  {...register('diameter')}
                  value={values.diameter}
                  onValueChange={(value) => {
                    const maskedValue = moneyMask(value, '', 2);
                    setValue('diameter', maskedValue);
                  }}
                />

                {/* Height */}
                <Input
                  size="md"
                  type="text"
                  label="Altura"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  endContent={<p className="text-sm font-medium">m</p>}
                  errorMessage={errors.height?.message}
                  {...register('height')}
                  value={values.height}
                  onValueChange={(value) => {
                    const maskedValue = moneyMask(value, '', 2);
                    setValue('height', maskedValue);
                  }}
                />

                {/* Width */}
                <Input
                  size="md"
                  type="text"
                  label="Largura"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  endContent={<p className="text-sm font-medium">m</p>}
                  errorMessage={errors.width?.message}
                  {...register('width')}
                  value={values.width}
                  onValueChange={(value) => {
                    const maskedValue = moneyMask(value, '', 2);
                    setValue('width', maskedValue);
                  }}
                />

                {/* Licensing Date */}
                <DatepickerInput
                  control={control}
                  name="licensingDate"
                  inputProps={{
                    size: 'md',
                    label: 'Data de licenciamento',
                    variant: 'underlined',
                    errorMessage: errors.licensingDate?.message,
                  }}
                  datePickerProps={{
                    minDate: new Date(),
                  }}
                />

                {/* Research due date */}
                <DatepickerInput
                  control={control as any}
                  name="researcDate"
                  inputProps={{
                    size: 'md',
                    label: 'Data da pesquisa',
                    variant: 'underlined',
                    errorMessage: errors.researcDate?.message,
                  }}
                  datePickerProps={{
                    minDate: new Date(),
                  }}
                />

                <div className="col-span-3 col-start-1 grid grid-cols-3 gap-4">
                  {/* Title */}
                  <p className="col-span-3 -mb-5 mt-4 text-base font-semibold text-foreground-500">
                    Opcional
                  </p>

                  {/* Brand */}
                  <Input
                    size="md"
                    type="text"
                    label="Marca"
                    variant="underlined"
                    classNames={{
                      label: 'font-bold',
                    }}
                    {...register('brand')}
                    value={values.brand}
                  />

                  {/* Model */}
                  <Input
                    size="md"
                    type="text"
                    label="Modelo"
                    variant="underlined"
                    classNames={{
                      label: 'font-bold',
                    }}
                    {...register('model')}
                    value={values.model}
                  />

                  {/* Version */}
                  <Input
                    size="md"
                    type="text"
                    label="Versão"
                    variant="underlined"
                    classNames={{
                      label: 'font-bold',
                    }}
                    {...register('version')}
                    value={values.version}
                  />

                  {/* Manufacture Year */}
                  <DatepickerInput
                    control={control}
                    name="manufactureYear"
                    inputProps={{
                      size: 'md',
                      label: 'Ano de fabricação',
                      variant: 'underlined',
                    }}
                    datePickerProps={{
                      maxDate: new Date(),
                      showYearPicker: true,
                      dateFormat: 'yyyy',
                    }}
                  />

                  {/* Model Year */}
                  <DatepickerInput
                    control={control}
                    name="modelYear"
                    inputProps={{
                      size: 'md',
                      label: 'Ano do modelo',
                      variant: 'underlined',
                    }}
                    datePickerProps={{
                      maxDate: new Date(),
                      showYearPicker: true,
                      dateFormat: 'yyyy',
                    }}
                  />
                </div>
                <div />
              </div>
            </ModalBody>

            <ModalFooter>
              {/* Cancel Button */}
              <Button color="danger" onPress={handleClose}>
                Cancelar
              </Button>

              {/* Save Button */}
              <Button type="submit" isLoading={isLoading} isDisabled={!isValid}>
                <p>{vehicle ? 'Alterar' : 'Cadastrar'}</p>
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default forwardRef(RegisterVehicleModal);

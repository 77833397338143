/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { RegisterVehicleModalHandle } from '../../../../components/RegisterVehicleModal/RegisterVehicleModal';
import { ITipoVeiculo } from '../../../../interfaces/response/TipoVeiculo/ITipoVeiculo';
import { IVeiculo } from '../../../../interfaces/response/Veiculo/IVeiculo';
import {
  ObterTipoVeiculo,
  ObterVeiculo,
} from '../../../../redux/features/clientsData/clientsDataThunk';
import { useAsyncDispatch } from '../../../../redux/store';

export interface IProps {
  vehicles: IVeiculo[];
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  handleGetVehicles: () => void;
  modalRef: React.RefObject<RegisterVehicleModalHandle>;
  vehicleTypeOptions: ITipoVeiculo[];
}

const useVeiculo = (): IProps => {
  const [filter, setFilter] = useState<string>('');
  const modalRef = useRef<RegisterVehicleModalHandle>(null);
  const dispatch = useAsyncDispatch();
  const [sortedVehicle, setSortedVehicle] = useState<IVeiculo[]>([]);
  const [vehicles, setVehicles] = useState<IVeiculo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<ITipoVeiculo[]>(
    []
  );

  useEffect(() => {
    const sortedArray = vehicles.filter((vehicleAux) =>
      vehicleAux.placa.toLowerCase().includes(filter.toLowerCase())
    );
    setSortedVehicle(sortedArray);
  }, [filter]);

  const handleGetVehicleTypes = async (): Promise<void> => {
    await dispatch(ObterTipoVeiculo())
      .unwrap()
      .then((response) => setVehicleTypeOptions(response.tipoVeiculos))
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao buscar os tipos de veículo, por favor tente mais tarde.'
        );
      });
  };

  const consultarVeiculo = async (): Promise<void> => {
    setLoading(true);

    await dispatch(ObterVeiculo())
      .unwrap()
      .then(async (response) => {
        await handleGetVehicleTypes();
        setSortedVehicle(response.veiculos);
        setVehicles(response.veiculos);
      })
      .catch((error) => {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_CANCELED') return;

        toast.error(
          'Erro ao consultar os veículos, por favor tente mais tarde'
        );
      })
      .finally(() => setLoading(false));
  };

  const handleGetVehicles = (): void => {
    consultarVeiculo();
  };

  useEffect(() => {
    consultarVeiculo();
  }, []);

  return {
    vehicles: sortedVehicle,
    setFilter,
    loading,
    modalRef,
    vehicleTypeOptions,
    handleGetVehicles,
  };
};

export default useVeiculo;

import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import React from 'react';
import { ITabelaPreco } from '../../../../interfaces/response/TabelaPreco/ITabelaPreco';
import { ITipoVeiculo } from '../../../../interfaces/response/TipoVeiculo/ITipoVeiculo';
import useLocationPriceTable, { Header } from './hooks/useLocationPriceTable';

export interface ILocationPriceTable {
  locationsPrices: ITabelaPreco[];
  onRowClick?: (locationPrice: ITabelaPreco) => void;
  vehicleTypeOptions: ITipoVeiculo[];
}

const LocationPriceTable: React.FC<ILocationPriceTable> = ({
  locationsPrices,
  onRowClick,
  vehicleTypeOptions,
}): JSX.Element => {
  const {
    cellData,
    columns,
    handleRowClick,
    setSortDescriptor,
    sortDescriptor,
    sortedLocationsPrices,
  } = useLocationPriceTable({
    locationsPrices,
    onRowClick,
    vehicleTypeOptions,
  });

  return (
    <Table
      aria-label="Tabela de tabelas preço"
      selectionMode="single"
      onRowAction={handleRowClick}
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
    >
      <TableHeader columns={columns}>
        {({ key, name }) => (
          <TableColumn key={key} allowsSorting>
            {name}
          </TableColumn>
        )}
      </TableHeader>

      <TableBody
        items={sortedLocationsPrices}
        emptyContent="Nenhuma tabela preço no momento"
      >
        {(item) => (
          <TableRow key={item.idTabelaPreco}>
            {(columnKey) => (
              <TableCell>
                <p className="text-foreground-600">
                  {cellData(item, columnKey as Header)}
                </p>
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default LocationPriceTable;

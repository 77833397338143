import { useDisclosure } from '@nextui-org/react';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { useReactToPrint } from 'react-to-print';
import {
  Theme,
  ThemeContext,
} from '../../../../../contexts/ThemeContext/ThemeContext';
import { IBadRequestResponse } from '../../../../../interfaces/IBadRequestResponse';
import { IOrderCheckResponse } from '../../../../../interfaces/response/Orders/IOrderCheckResponse';
import { postCheckOrder } from '../../../../../redux/features/orders/ordersThunk';
import { useAsyncDispatch } from '../../../../../redux/store';

interface IUsePrintOrderTagModal {
  isOpen: boolean;
  onOpenChange: () => void;
  theme: Theme;
  handleOpenModal: (nfKey: string, auto: boolean) => void;
  handleOpenModalToShowTag: (printedTag: IOrderCheckResponse) => void;
  handleCloseModal: () => void;
  handlePrint: () => void;
  loading: boolean;
  tagData: IOrderCheckResponse | null;
  tagRef: MutableRefObject<null>;
}

interface IUsePrintOrderTagModalProps {
  onTagPrinted?: (tagData: IOrderCheckResponse) => void;
}

export const usePrintOrderTagModal = ({
  onTagPrinted,
}: IUsePrintOrderTagModalProps): IUsePrintOrderTagModal => {
  const dispatch = useAsyncDispatch();
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const { theme } = useContext(ThemeContext);
  const cancelTokenRef = useRef<CancelTokenSource>();
  const cancelToken = axios.CancelToken;
  const [loading, setLoading] = useState<boolean>(false);
  const [tagData, setTagData] = useState<IOrderCheckResponse | null>(null);
  const [automaticMode, setAutomaticMode] = useState<boolean | null>(null);

  const tagRef = useRef(null);

  const printableComponentRef = useCallback(() => {
    return tagRef.current;
  }, [tagRef.current]);

  const handleCloseModal = (): void => {
    onClose();
    if (onTagPrinted && tagData && automaticMode !== null)
      onTagPrinted(tagData);
    setTagData(null);
    setAutomaticMode(null);
  };

  const handleOpenModalToShowTag = (printedTag: IOrderCheckResponse): void => {
    onOpen();
    setAutomaticMode(null);
    setTagData(printedTag);
  };

  const handlePrint = useReactToPrint({
    content: printableComponentRef,
    documentTitle: 'Etiqueta',
    onAfterPrint: handleCloseModal,
    removeAfterPrint: true,
  });

  const handleCheckOrder = async (nfKey: string): Promise<void> => {
    setLoading(true);
    const source: CancelTokenSource = cancelToken.source();
    cancelTokenRef.current = source;

    await dispatch(postCheckOrder({ body: { nfKey }, cancelToken: source }))
      .unwrap()
      .then((response: IOrderCheckResponse) => {
        setTagData(response);

        toast.success('Pedido conferido com sucesso');
      })
      .catch((error) => {
        const axiosError = error as AxiosError<IBadRequestResponse>;
        if (axiosError.code === 'ERR_CANCELED') return;
        handleCloseModal();

        toast.error(
          error.response?.data?.message ||
            'Erro ao conferir pedido, por favor tente mais tarde'
        );
      })
      .finally(() => setLoading(false));
  };

  const handleOpenModal = (nfKey: string, auto: boolean): void => {
    onOpen();
    setAutomaticMode(auto);
    handleCheckOrder(nfKey);
  };

  useEffect(() => {
    if (!automaticMode || tagRef.current === null || tagData === null) return;
    handlePrint();
  }, [automaticMode, tagRef.current, tagData]);

  return {
    isOpen,
    onOpenChange,
    theme,
    handleOpenModal,
    handleOpenModalToShowTag,
    handleCloseModal,
    handlePrint,
    loading,
    tagData,
    tagRef,
  };
};

import polyline from '@mapbox/polyline';
import { FC, useEffect, useRef } from 'react';
// eslint-disable-next-line import/no-unresolved
import { createRoot, Root } from 'react-dom/client';

export interface PolylineProps {
  map: google.maps.Map;
  path: string;
  strokeColor: string;
}

const Polyline: FC<PolylineProps> = ({ map, path, strokeColor }) => {
  const markerRef = useRef<google.maps.Polyline>();
  const rootRef = useRef<Root>();

  useEffect(() => {
    if (rootRef.current) return;

    const markerContainer = document.createElement('div');
    markerContainer.setAttribute('id', 'rendered');
    rootRef.current = createRoot(markerContainer);

    markerRef.current = new google.maps.Polyline({
      path: polyline.decode(path).map(([lat, lng]) => ({ lat, lng })),
      map,
      strokeWeight: 6,
      strokeColor,
    });
  }, []);

  useEffect((): undefined | (() => void) => {
    if (!markerRef.current) return;

    // Remove the old polyline from the map
    markerRef.current.setMap(null);

    // Create a new polyline with the updated path
    markerRef.current = new google.maps.Polyline({
      path: polyline.decode(path).map(([lat, lng]) => ({ lat, lng })),
      map,
      strokeWeight: 6,
      strokeColor,
    });

    // Set the new polyline on the map
    markerRef.current.setMap(map);

    // Cleanup function to remove the polyline when the component unmounts or the path changes
    // eslint-disable-next-line consistent-return
    return () => {
      if (markerRef.current) {
        markerRef.current.setMap(null);
      }
    };
  }, [map, path, strokeColor]);

  return null;
};

export default Polyline;

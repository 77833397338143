import {
  SortDescriptor,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import React, { useMemo, useState } from 'react';
import {
  formatarMedidaGenerico,
  formatarMoeda,
} from '../../../../../util/mask';

interface IPedido {
  idRotaPedido: string;
  idRota: string;
  idCte: string;
  volumesConferidos: number;
  vlTotalTributo: number;
  vlCarga: number;
  vlCargaAverbado: number;
  pesoReal: number;
  unidadeMedidaPesoReal: string;
  pesoCubado: number;
  unidadeMedidaPesoCubado: string;
  pesoTaxado: number;
  unidadeMedidaPesoTaxado: string;
  adValorem: number;
  fretePeso: number;
  gris: number;
  impostoRepassado: number;
  vlTotalPrestacao: number;
  vlTotalReceber: number;
  vTotTribICMS: number;
  volumes: number;
  volume: number;
  unidadeMedidaVolume: string;
  chaveNFe: string;
  dtEmissao: Date;
  dtLimiteEntrega: Date;
  logradouro: string;
  numero: string;
  complemento: null;
  bairro: string;
  cidade: string;
  estado: string;
  lat: string;
  lng: string;
  cep: string;
  status: number;
  ordem: number;
}
const PedidosTable = ({ pedidos }: { pedidos: IPedido[] }): JSX.Element => {
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'Ordem de entrega',
    direction: 'descending',
  });

  const columns: any[] = [
    {
      key: 'Ordem',
      name: 'ORDEM',
      size: 30,
    },
    {
      key: 'Valor',
      name: 'VALOR',
      size: 30,
    },
    {
      key: 'Peso',
      name: 'PESO REAL',
      size: 30,
    },
    {
      key: 'Volume',
      name: 'VOLUMES',
      size: 30,
    },
    {
      key: 'Endereço',
      name: 'ENDEREÇO',
      size: 200,
    },
    {
      key: 'Numero',
      name: 'NÚMERO',
      size: 200,
    },
    {
      key: 'Bairro',
      name: 'BAIRRO',
      size: 200,
    },
    {
      key: 'Cidade',
      name: 'CÍDADE',
      size: 200,
    },
  ];

  const cellData = (order: IPedido, columnKey: any): number | string => {
    switch (columnKey) {
      case 'Ordem':
        return order.ordem;
      case 'Valor':
        return formatarMoeda(order.vlCargaAverbado);
      case 'Peso':
        return formatarMedidaGenerico(order.pesoTaxado, 'Kg');
      case 'Volume':
        return order.volumes;
      case 'Endereço':
        return order.logradouro;
      case 'Numero':
        return order.logradouro;
      case 'Bairro':
        return order.bairro;
      case 'Cidade':
        return order.cidade;
      default:
        return '';
    }
  };

  const sortedOrders = useMemo(() => {
    return [...pedidos].sort((a: IPedido, b: IPedido) => {
      const first = cellData(a, sortDescriptor.column as any) as number;
      const second = cellData(b, sortDescriptor.column as any) as number;

      let cmp = 0;
      if (first < second) cmp = -1;
      if (first > second) cmp = 1;

      return sortDescriptor.direction === 'ascending' ? -cmp : cmp;
    });
  }, [sortDescriptor, pedidos]);

  return (
    <div>
      <Table
        aria-label="Tabela da rota selecionada"
        isHeaderSticky
        selectionMode="single"
        classNames={{
          wrapper: 'max-h-[50vh]',
        }}
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={columns}>
          {({ key, name, size }) => (
            <TableColumn align="center" width={size} key={key} allowsSorting>
              {name}
            </TableColumn>
          )}
        </TableHeader>

        <TableBody items={sortedOrders} emptyContent="Nenhuma rota no momento">
          {(item) => (
            <TableRow key={item.idCte}>
              {(columnKey) => (
                <TableCell>
                  <p className="text text-small text-foreground-600">
                    {cellData(item, columnKey as any)}
                  </p>
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default PedidosTable;

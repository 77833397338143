import React, { FC } from 'react';

export interface BlankStateProps {
  label: string;
}

const BlankState: FC<BlankStateProps> = ({ label }): JSX.Element => {
  return (
    <div>
      <p className="flex w-full justify-center p-8 text-base text-default-500">
        {label}
      </p>
    </div>
  );
};

export default BlankState;

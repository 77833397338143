import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, CancelTokenSource } from 'axios';
import { RoutesAPI } from '../../../config/api';
import { IBadRequestResponse } from '../../../interfaces/IBadRequestResponse';
import { IOrderCheckResponse } from '../../../interfaces/response/Orders/IOrderCheckResponse';

export const postCheckOrder = createAsyncThunk(
  'rotas/postCheckOrder',
  async (
    args: {
      body: { nfKey: string };
      cancelToken: CancelTokenSource;
    },
    { rejectWithValue }
  ): Promise<IOrderCheckResponse | any> => {
    const {
      cancelToken,
      body: { nfKey },
    } = args;

    try {
      const { data } = await RoutesAPI.post<IOrderCheckResponse>(
        `/api/v1/Order/check`,
        { ChaveNFe: nfKey },
        {
          cancelToken: cancelToken.token,
        }
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

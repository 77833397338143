import {
  Accordion,
  AccordionItem,
  Button,
  Card,
  Divider,
  Input,
  Select,
  SelectItem,
  Switch,
} from '@nextui-org/react';
import { motion } from 'framer-motion';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { IoIosArrowBack } from 'react-icons/io';
import DatePickerInput from '../../../../components/DatepickerInput/DatepickerInput';
import { Deposito } from '../../../../interfaces/IUsuario';
import { IDetalhesRota } from '../../../../interfaces/response/DetalheRota/IDetalheRotaResponse';
import { IMotorista } from '../../../../interfaces/response/Motorista/IMotorista';
import { IVeiculoMotorista } from '../../../../interfaces/response/VeiculoMotorista/IVeiculoMotorista';
import { cpfMask } from '../../../../util/mask';
import { RouteError } from '../../hooks/useCreateRoutes';
import { ConfirmationModalHandle } from './components/ConfirmationModal/ConfirmationModal';
import useCreateRouteAccordion from './hooks/useCreateRouteAccordion';

export interface CreateRouteAccordionProps {
  updateDriver: (driver: IMotorista | undefined) => void;
  updateVehicle: (vehicle: IVeiculoMotorista | undefined) => void;
  updateReturnWarehouse: (returnWarehousel: boolean) => void;
  onRouteCreated: () => void;
  warehouse: Deposito | undefined;
  routeErrors: Set<RouteError>;
  confirmModalRef: React.RefObject<ConfirmationModalHandle>;
  routeDetails: IDetalhesRota | undefined;
  isLoadingRouteDetails: boolean;
}

const CreateRouteAccordion: FC<CreateRouteAccordionProps> = ({
  updateDriver,
  updateVehicle,
  updateReturnWarehouse,
  onRouteCreated,
  warehouse,
  routeErrors,
  confirmModalRef,
  routeDetails,
  isLoadingRouteDetails,
}): JSX.Element => {
  const {
    control,
    driver,
    drivers,
    errors,
    handleSubmit,
    handleSubmitRoute,
    isDriversLoading,
    isValid,
    isVehiclesLoading,
    loading,
    name,
    register,
    theme,
    vehicle,
    vehicles,
    returnWarehouse,
  } = useCreateRouteAccordion({
    updateDriver,
    updateVehicle,
    updateReturnWarehouse,
    onRouteCreated,
    warehouse,
    routeErrors,
    confirmModalRef,
    routeDetails,
    isLoadingRouteDetails,
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      exit={{ opacity: 0 }}
    >
      <Card className="min-w-[500px] overflow-visible" radius="sm">
        <Accordion>
          <AccordionItem
            key="register-route"
            aria-label="Registrar rota"
            title={
              <h1 className="text-lg font-semibold text-foreground-700">
                Cadastrar Rota
              </h1>
            }
            indicator={<IoIosArrowBack className="text-foreground-700" />}
            className="px-2"
          >
            <form
              onSubmit={handleSubmit(handleSubmitRoute)}
              className="-mt-2 flex flex-col gap-2 pb-2"
            >
              <Divider />

              {/* Route Name */}
              <Input
                size="md"
                type="text"
                label="Nome da rota"
                variant="underlined"
                classNames={{
                  label: 'font-bold',
                }}
                errorMessage={errors.name?.message}
                {...register('name')}
                value={name}
              />

              {/* Driver */}
              <Controller
                control={control}
                name="driver"
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Motorista"
                    variant="underlined"
                    size="md"
                    placeholder="Selecione um motorista"
                    classNames={{
                      label: 'font-bold',
                      popoverContent: `${theme}`,
                    }}
                    selectedKeys={driver !== '' ? [driver] : new Set([])}
                    onBlur={field.onBlur}
                    className={`${theme}`}
                    isLoading={isDriversLoading}
                    onSelectionChange={field.onChange}
                  >
                    {drivers.map(
                      ({ idMotorista, idTipoMotorista, nome, cpf }) => {
                        const driverType =
                          idTipoMotorista === 1 ? 'Frota' : 'Agregado';
                        const label = `${driverType} - ${nome} ${cpfMask(cpf)}`;
                        return (
                          <SelectItem
                            key={idMotorista.toString()}
                            value={idMotorista.toString()}
                            textValue={label}
                          >
                            <p className="text-foreground-700">
                              <span className="font-semibold">
                                {driverType}:{' '}
                              </span>
                              {nome} {cpfMask(cpf)}
                            </p>
                          </SelectItem>
                        );
                      }
                    )}
                  </Select>
                )}
              />

              {/* Vehicle */}
              <Controller
                control={control}
                name="vehicle"
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Veículo"
                    variant="underlined"
                    size="md"
                    placeholder="Selecione o veículo"
                    classNames={{
                      label: 'font-bold',
                      popoverContent: `${theme}`,
                    }}
                    selectedKeys={vehicle !== '' ? [vehicle] : []}
                    onBlur={field.onBlur}
                    className={`${theme}`}
                    onSelectionChange={field.onChange}
                    isDisabled={driver === ''}
                    isLoading={isVehiclesLoading}
                  >
                    {vehicles.map(
                      ({ veiculo, idVeiculoMotorista, veiculoPadrao }) => {
                        const label = `Placa ${veiculo.placa.toUpperCase()} - ${
                          veiculo.cubagem_Diametro
                        }x${veiculo.cubagem_Altura}x${veiculo.cubagem_Largura}${
                          veiculoPadrao ? ' (padrão)' : ''
                        }`;
                        return (
                          <SelectItem
                            key={idVeiculoMotorista.toString()}
                            value={idVeiculoMotorista.toString()}
                            textValue={label}
                          >
                            <p className="text-foreground-700">
                              <span className="font-semibold">
                                Placa {veiculo.placa.toUpperCase()} -{' '}
                              </span>
                              {veiculo.cubagem_Diametro}x
                              {veiculo.cubagem_Altura}x{veiculo.cubagem_Largura}
                              {veiculoPadrao ? ' (padrão)' : ''}
                            </p>
                          </SelectItem>
                        );
                      }
                    )}
                  </Select>
                )}
              />

              <div className="flex flex-row items-start justify-between">
                {/* Departure date */}
                <DatePickerInput
                  control={control}
                  name="departureDate"
                  inputProps={{
                    size: 'md',
                    label: 'Data de saída',
                    variant: 'underlined',
                    errorMessage: errors.departureDate?.message,
                    className: 'max-w-[128px]',
                  }}
                  datePickerProps={{
                    minDate: new Date(),
                  }}
                />

                {/* Return to warehouse */}
                <Controller
                  control={control}
                  name="returnToWarehouse"
                  render={({ field: { onBlur, onChange, disabled } }) => (
                    <Switch
                      size="sm"
                      {...register('returnToWarehouse')}
                      color="default"
                      isSelected={
                        drivers.find(
                          (driverAux) =>
                            driverAux.idMotorista.toString() === driver
                        )?.idTipoMotorista === 1
                          ? true
                          : returnWarehouse
                      }
                      onBlur={onBlur}
                      onChange={onChange}
                      isDisabled={
                        disabled ||
                        drivers.find(
                          (driverAux) =>
                            driverAux.idMotorista.toString() === driver
                        )?.idTipoMotorista === 1
                      }
                      className="mt-7 px-4"
                    >
                      Retornar para centro de distribuição
                    </Switch>
                  )}
                />
              </div>

              {/* Save Button */}
              <div className="flex justify-end">
                <Button
                  type="submit"
                  isLoading={loading || isLoadingRouteDetails}
                  isDisabled={!isValid}
                >
                  <p>Cadastrar</p>
                </Button>
              </div>
            </form>
          </AccordionItem>
        </Accordion>
      </Card>
    </motion.div>
  );
};

export default CreateRouteAccordion;

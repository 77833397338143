import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
  Theme,
  ThemeContext,
  ThemeContextType,
} from '../../../contexts/ThemeContext/ThemeContext';
import { User } from '../../../interfaces/IUsuario';
import { selectUsuario } from '../../../redux/features/generalData/generalDataSelectors';
import { logOut } from '../../../redux/features/generalData/generalDataSlice';
import { useAsyncDispatch } from '../../../redux/store';

export interface UseUserDropdown {
  theme: Theme;
  user: User | undefined;
  navigate: NavigateFunction;
  handleLogOut: () => void;
}

const useUserDropdown = (): UseUserDropdown => {
  const user = useSelector(selectUsuario);
  const { theme } = useContext<ThemeContextType>(ThemeContext);
  const navigate = useNavigate();
  const dispatch = useAsyncDispatch();

  const handleLogOut = (): void => {
    dispatch(logOut());
  };

  return {
    theme,
    user,
    navigate,
    handleLogOut,
  };
};

export default useUserDropdown;

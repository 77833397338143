import { zodResolver } from '@hookform/resolvers/zod';
import { useDisclosure } from '@nextui-org/react';
import { useContext, useState } from 'react';
import {
  FieldErrors,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { z } from 'zod';
import {
  Theme,
  ThemeContext,
} from '../../../contexts/ThemeContext/ThemeContext';
import { ICadastraTipoVeiculoRequest } from '../../../interfaces/request/TipoVeiculo/ICadastraTipoVeiculoRequest';
import { IEditaTipoVeiculoRequest } from '../../../interfaces/request/TipoVeiculo/IEditaTipoVeiculoRequest';
import { ITipoVeiculo } from '../../../interfaces/response/TipoVeiculo/ITipoVeiculo';
import {
  CadastrarTipoVeiculo,
  EditarTipoVeiculo,
} from '../../../redux/features/clientsData/clientsDataThunk';
import { selectUsuario } from '../../../redux/features/generalData/generalDataSelectors';
import { useAsyncDispatch } from '../../../redux/store';
import { moneyMask } from '../../../util/mask';

const formSchema = z.object({
  categoryName: z.string().min(1, 'Nome da categoria é obrigatório'),
  value: z
    .string()
    .min(1, 'Valor é obrigatório')
    .refine(
      (value) => Number(value.replaceAll('.', '').replace(',', '.')) > 0,
      'Valor precisa ser maior que zero'
    ),
  weight: z
    .string()
    .min(1, 'Peso é obrigatório')
    .refine(
      (value) => Number(value.replaceAll('.', '').replace(',', '.')) > 0,
      'Peso precisa ser maior que zero'
    ),
  diameter: z
    .string()
    .min(1, 'Diâmetro é obrigatório')
    .refine(
      (value) => Number(value.replaceAll('.', '').replace(',', '.')) > 0,
      'Diâmetro precisa ser maior que zero'
    ),
  height: z
    .string()
    .min(1, 'Altura é obrigatória')
    .refine(
      (value) => Number(value.replaceAll('.', '').replace(',', '.')) > 0,
      'Altura precisa ser maior que zero'
    ),
  width: z
    .string()
    .min(1, 'Largura é obrigatória')
    .refine(
      (value) => Number(value.replaceAll('.', '').replace(',', '.')) > 0,
      'Largura precisa ser maior que zero'
    ),
});

type FormSchema = z.infer<typeof formSchema>;

interface UseRegisterVehicleTypeModalProps {
  onUpdate?: () => void;
}

export interface UseRegisterVehicleTypeModal {
  errors: FieldErrors<FormSchema>;
  handleSubmitVehicleType: (data: FormSchema) => Promise<void>;
  handleClose: () => void;
  handleSubmit: UseFormHandleSubmit<FormSchema>;
  handleOpenEdit: (vehicleTypeAux: ITipoVeiculo) => void;
  isLoading: boolean;
  isOpen: boolean;
  isValid: boolean;
  onOpen: () => void;
  onOpenChange: () => void;
  register: UseFormRegister<FormSchema>;
  theme: Theme;
  setValue: UseFormSetValue<FormSchema>;
  values: FormSchema;
  vehicleType: ITipoVeiculo | null;
}

const useRegisterVehicleTypeModal = ({
  onUpdate,
}: UseRegisterVehicleTypeModalProps): UseRegisterVehicleTypeModal => {
  const dispatch = useAsyncDispatch();
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const { theme } = useContext(ThemeContext);
  const user = useSelector(selectUsuario);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [vehicleType, setVehicleType] = useState<ITipoVeiculo | null>(null);
  const defaultValues: Partial<FormSchema> = {
    categoryName: '',
    value: '0,00',
    weight: '0,00',
    diameter: '0,00',
    height: '0,00',
    width: '0,00',
  };
  const {
    register,
    handleSubmit,
    clearErrors,
    formState,
    reset,
    setValue,
    watch,
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resetOptions: {
      keepDefaultValues: true,
    },
  });
  const { errors, isValid } = formState;
  const values = watch();

  const handleClose = (): void => {
    onClose();
    setVehicleType(null);
    clearErrors();
    reset();
  };

  const handleCreateVehicleType = async (data: FormSchema): Promise<void> => {
    if (!user) return;

    const { _Id: userId } = user;
    const { categoryName, diameter, height, value, weight, width } = data;

    const body: ICadastraTipoVeiculoRequest = {
      idDeposito: user.defaultWarehouse.idDeposito,
      categoria: categoryName,
      icone: '-',
      cubagem_Altura: Number(height.replaceAll('.', '').replace(',', '.')),
      cubagem_Diametro: Number(diameter.replaceAll('.', '').replace(',', '.')),
      cubagem_Largura: Number(width.replaceAll('.', '').replace(',', '.')),
      peso: Number(weight.replaceAll('.', '').replace(',', '.')),
      usuarioCadastro: userId,
      valor: Number(value.replaceAll('.', '').replace(',', '.')),
    };

    setIsLoading(true);
    await dispatch(
      CadastrarTipoVeiculo({
        body,
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Tipo de Veículo cadastrado com sucesso!');
        handleClose();
        if (onUpdate) onUpdate();
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao realizar o cadastro do tipo de veículo, por favor tente mais tarde.'
        );
      })
      .finally(() => setIsLoading(false));
  };

  const handleEditVehicleType = async (data: FormSchema): Promise<void> => {
    if (!user || !vehicleType) return;

    const { _Id: userId } = user;
    const { categoryName, diameter, height, value, weight, width } = data;

    const body: IEditaTipoVeiculoRequest = {
      categoria: categoryName,
      cubagem_Altura: Number(height.replaceAll('.', '').replace(',', '.')),
      cubagem_Diametro: Number(diameter.replaceAll('.', '').replace(',', '.')),
      cubagem_Largura: Number(width.replaceAll('.', '').replace(',', '.')),
      icone: '-',
      idTipoVeiculo: vehicleType.idTipoVeiculo,
      peso: Number(weight.replaceAll('.', '').replace(',', '.')),
      usuarioAlteracao: userId,
      valor: Number(value.replaceAll('.', '').replace(',', '.')),
    };

    setIsLoading(true);
    await dispatch(
      EditarTipoVeiculo({
        body,
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Tipo de Veículo editado com sucesso!');
        handleClose();
        if (onUpdate) onUpdate();
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao realizar a alteração do tipo deveículo, por favor tente mais tarde.'
        );
      })
      .finally(() => setIsLoading(false));
  };

  const handleSubmitVehicleType = async (data: FormSchema): Promise<void> => {
    if (vehicleType) handleEditVehicleType(data);
    else handleCreateVehicleType(data);
  };

  const handleOpenEdit = (vehicleTypeAux: ITipoVeiculo): void => {
    setVehicleType(vehicleTypeAux);
    setValue('categoryName', vehicleTypeAux?.categoria ?? '');
    setValue('value', moneyMask(vehicleTypeAux?.valor, '', 2) ?? '');
    setValue('weight', moneyMask(vehicleTypeAux?.peso, '', 2) ?? '');
    setValue(
      'diameter',
      moneyMask(vehicleTypeAux?.cubagem_Diametro, '', 2) ?? ''
    );
    setValue('height', moneyMask(vehicleTypeAux?.cubagem_Altura, '', 2) ?? '');
    setValue('width', moneyMask(vehicleTypeAux?.cubagem_Largura, '', 2) ?? '');
    onOpen();
  };

  return {
    errors,
    handleSubmitVehicleType,
    handleClose,
    handleSubmit,
    handleOpenEdit,
    isLoading,
    isOpen,
    isValid,
    onOpen,
    onOpenChange,
    register,
    theme,
    setValue,
    values,
    vehicleType,
  };
};

export default useRegisterVehicleTypeModal;

import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { ITipoVeiculo } from '../../interfaces/response/TipoVeiculo/ITipoVeiculo';
import { formatarData } from '../../util/format';
import { moneyMask } from '../../util/mask';
import useRegisterVehicleTypeModal from './hooks/useRegisterVehicleTypeModal';

export interface RegisterVehicleTypeModalHandle {
  openToEdit: (vehicleAux: ITipoVeiculo) => void;
  closeModal: () => void;
}

interface RegisterVehicleTypeModalProps {
  onUpdate?: () => void;
}

const RegisterVehicleTypeModal: ForwardRefRenderFunction<
  RegisterVehicleTypeModalHandle,
  RegisterVehicleTypeModalProps
> = ({ onUpdate }, ref): JSX.Element => {
  const {
    isOpen,
    errors,
    handleClose,
    handleOpenEdit,
    handleSubmit,
    handleSubmitVehicleType,
    isLoading,
    isValid,
    onOpen,
    onOpenChange,
    register,
    setValue,
    theme,
    values,
    vehicleType,
  } = useRegisterVehicleTypeModal({ onUpdate });

  useImperativeHandle(ref, () => ({
    openToEdit: (vehicleTypeAux: ITipoVeiculo) => {
      handleOpenEdit(vehicleTypeAux);
    },
    closeModal: () => {
      handleClose();
    },
  }));

  return (
    <>
      <Button
        onPress={onOpen}
        size="md"
        startContent={
          <div>
            <FaPlusCircle />
          </div>
        }
      >
        <p>Cadastrar tipo de veículo</p>
      </Button>

      <Modal
        isOpen={isOpen}
        size="4xl"
        onOpenChange={onOpenChange}
        className={`${theme} overflow-visible`}
        onClose={handleClose}
      >
        <ModalContent>
          <form onSubmit={handleSubmit(handleSubmitVehicleType)}>
            <ModalHeader className="text-default-600">
              <p>{vehicleType ? 'Editar' : 'Cadastrar'} tipo de veículo</p>
            </ModalHeader>

            <ModalBody>
              {/* User information */}
              {vehicleType && (
                <Card
                  className="grid grid-cols-3 bg-foreground-100 p-4"
                  shadow="none"
                >
                  <div>
                    <p className="font-semibold">ID do tipo de veículo:</p>
                    <p className="text-default-600">
                      {vehicleType.idTipoVeiculo}
                    </p>
                  </div>

                  <div>
                    <p className="font-semibold">Criado por:</p>
                    <p className="text-default-600">
                      {vehicleType.nomeUsuarioCadastro} em{' '}
                      {formatarData(vehicleType.dataCadastro)}
                    </p>
                  </div>

                  <div
                    className={`${
                      vehicleType.nomeUsuarioAlteracao ? '' : 'hidden'
                    }`}
                  >
                    <p className="font-semibold">Alterado por:</p>
                    <p className="text-default-600">
                      {vehicleType.nomeUsuarioAlteracao} em{' '}
                      {formatarData(vehicleType.dataAlteracao)}{' '}
                    </p>
                  </div>
                </Card>
              )}

              {/* Inputs Grid */}
              <div className="grid grid-cols-2 gap-4">
                {/* Category Name */}
                <Input
                  size="md"
                  type="text"
                  label="Categoria"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  errorMessage={errors.categoryName?.message}
                  {...register('categoryName')}
                  value={values.categoryName}
                />

                {/* Value */}
                <Input
                  size="md"
                  type="text"
                  label="Valor PGR"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  startContent={
                    <div className="pointer-events-none flex items-center">
                      <span className="text-small text-default-400">R$</span>
                    </div>
                  }
                  errorMessage={errors.value?.message}
                  {...register('value')}
                  value={values.value}
                  onValueChange={(value) => {
                    const maskedValue = moneyMask(value, '', 2);
                    setValue('value', maskedValue);
                  }}
                />

                {/* Weight */}
                <Input
                  size="md"
                  type="text"
                  label="Peso KG"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  endContent={<p className="text-sm font-medium">kg</p>}
                  errorMessage={errors.weight?.message}
                  {...register('weight')}
                  value={values.weight}
                  onValueChange={(value) => {
                    const maskedValue = moneyMask(value, '', 2);
                    setValue('weight', maskedValue);
                  }}
                />

                {/* Diameter */}
                <Input
                  size="md"
                  type="text"
                  label="Diâmetro M"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  endContent={<p className="text-sm font-medium">m</p>}
                  errorMessage={errors.diameter?.message}
                  {...register('diameter')}
                  value={values.diameter}
                  onValueChange={(value) => {
                    const maskedValue = moneyMask(value, '', 2);
                    setValue('diameter', maskedValue);
                  }}
                />

                {/* Height */}
                <Input
                  size="md"
                  type="text"
                  label="Altura M"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  endContent={<p className="text-sm font-medium">m</p>}
                  errorMessage={errors.height?.message}
                  {...register('height')}
                  value={values.height}
                  onValueChange={(value) => {
                    const maskedValue = moneyMask(value, '', 2);
                    setValue('height', maskedValue);
                  }}
                />

                {/* Width */}
                <Input
                  size="md"
                  type="text"
                  label="Largura M"
                  variant="underlined"
                  classNames={{
                    label: 'font-bold',
                  }}
                  endContent={<p className="text-sm font-medium">m</p>}
                  errorMessage={errors.width?.message}
                  {...register('width')}
                  value={values.width}
                  onValueChange={(value) => {
                    const maskedValue = moneyMask(value, '', 2);
                    setValue('width', maskedValue);
                  }}
                />
                <div />
              </div>
            </ModalBody>

            <ModalFooter>
              {/* Cancel Button */}
              <Button color="danger" onPress={handleClose}>
                Cancelar
              </Button>

              {/* Save Button */}
              <Button type="submit" isLoading={isLoading} isDisabled={!isValid}>
                <p>{vehicleType ? 'Alterar' : 'Cadastrar'}</p>
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default forwardRef(RegisterVehicleTypeModal);

import { Input, Spinner } from '@nextui-org/react';
import React from 'react';
import RegisterVehicleTypeModal from '../../../components/RegisterVehicleTypeModal/RegisterVehicleTypeModal';
import VehicleTypeTable from '../components/VehicleTypeTable/VehicleTypeTable';
import useTipoVeiculo from './hooks/useTipoVeiculo';

const TipoVeiculo = (): JSX.Element => {
  const { vehicleTypes, setFilter, loading, modalRef, handleGetVehicleTypes } =
    useTipoVeiculo();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-end justify-between">
        <p className="text-default-500">
          Selecione um tipo de veículo para ver seus detalhes ou edita-lo:
        </p>

        <div className="grid grid-cols-2 items-end gap-8 ">
          <Input
            size="md"
            type="text"
            label="Filtro"
            variant="underlined"
            placeholder="Categoria"
            autoFocus
            classNames={{
              label: 'font-bold',
            }}
            onValueChange={(value) => {
              setFilter(value);
            }}
          />

          <RegisterVehicleTypeModal
            ref={modalRef}
            onUpdate={handleGetVehicleTypes}
          />
        </div>
      </div>

      {loading && <Spinner label="Baixando conteúdo" color="default" />}

      {!loading && (
        <VehicleTypeTable
          vehicleTypes={vehicleTypes}
          onRowClick={(vehicle) => modalRef.current?.openToEdit(vehicle)}
        />
      )}
    </div>
  );
};

export default TipoVeiculo;

import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tab,
  Tabs,
} from '@nextui-org/react';
import React from 'react';
import { IDadosPedido } from '../../../../../interfaces/response/Pedido/IDadosPedido';
import DetalheCTE from './components/CTE/DetalheCTE';
import TabDetalheDestinatario from './components/Tabs/TabDetalheDestinatario';
import TabDetalheEmitente from './components/Tabs/TabDetalheEmitente';
import TabDetalheRemetente from './components/Tabs/TabDetalheRemetente';
import TabErros from './components/Tabs/TabErros';
import useModalDetalhePedido from './hooks/useModalDetalhePedido';

interface ModalDetalhePedidoProps {
  pedido: IDadosPedido | undefined;
  isOpen: boolean;
  handleClose: () => void;
}

const ModalDetalhePedido = ({
  pedido,
  isOpen,
  handleClose,
}: ModalDetalhePedidoProps): JSX.Element => {
  const { theme } = useModalDetalhePedido();

  const handleKeyPress = (target: any): void => {
    if (target.charCode === 27) {
      handleClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className={theme}
      onClose={handleClose}
      size="5xl"
      onKeyPress={handleKeyPress}
    >
      {pedido && (
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-default-500">
                CTE: {pedido?.pedido.nCT} <Divider />
              </ModalHeader>
              <ModalBody className="text-sm text-default-500">
                <DetalheCTE pedido={pedido.pedido} />
                <Tabs aria-label="Options">
                  <Tab key="destinatario" title="Destinatário">
                    <TabDetalheDestinatario pedido={pedido} />
                  </Tab>
                  <Tab key="emitente" title="Emitente">
                    <TabDetalheEmitente pedido={pedido} />
                  </Tab>
                  <Tab key="remetente" title="Remetente">
                    <TabDetalheRemetente pedido={pedido} />
                  </Tab>
                  {pedido?.erros && pedido?.erros.length > 0 && (
                    <Tab key="erros" title="Erros">
                      <div style={{ height: 300, overflow: 'auto' }}>
                        {pedido.erros.map((erro) => (
                          <div style={{ padding: 2 }}>
                            <TabErros erro={erro} />
                          </div>
                        ))}
                      </div>
                    </Tab>
                  )}
                </Tabs>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                {/* <Button color="primary" onPress={onClose}>
                Action
              </Button> */}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      )}
    </Modal>
  );
};

export default ModalDetalhePedido;

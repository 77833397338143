/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { CancelTokenSource } from 'axios';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useAsyncDispatch } from '../../../../redux/store';
import { IRouteOrderListModalHandle } from '../components/RouteOrderListModal/RouteOrderListModal';

export interface IUseAConferir {
  loading: boolean;
  modalRef: RefObject<IRouteOrderListModalHandle>;
}

const useAConferir = (): IUseAConferir => {
  const dispatch = useAsyncDispatch();
  const modalRef = useRef<IRouteOrderListModalHandle>(null);
  const cancelTokenRef = useRef<CancelTokenSource>();
  const cancelToken = axios.CancelToken;
  const [loading, setLoading] = useState<boolean>(true);

  // Cancel all remaining requests on component unmount
  useEffect(() => {
    return () => {
      if (cancelTokenRef.current) cancelTokenRef.current.cancel();
    };
  }, []);

  return {
    loading,
    modalRef,
  };
};

export default useAConferir;

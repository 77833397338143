import { zodResolver } from '@hookform/resolvers/zod';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { Key, useContext, useEffect, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import {
  Theme,
  ThemeContext,
} from '../../../contexts/ThemeContext/ThemeContext';
import { Deposito } from '../../../interfaces/IUsuario';
import { IRota } from '../../../interfaces/response/Rotas/IRota';
import {
  selectRoutesDate,
  selectUsuario,
} from '../../../redux/features/generalData/generalDataSelectors';
import { setRoutesDate } from '../../../redux/features/generalData/generalDataSlice';
import { getRoute } from '../../../redux/features/gerirRotas/gerirRotasThunk';
import { useAsyncDispatch } from '../../../redux/store';
import { formatarData } from '../../../util/format';
import { IContextGerirRotas } from '../GerirRotasLayout';

interface TabMenu {
  status: number;
  name: string;
  path: string;
}

export interface IUseGerirRotasLayout {
  handleRedirectTo: (path: string) => void;
  pathname: string;
  tabOptions: TabMenu[];
  contextHandler: IContextGerirRotas;
  rotas: IRota[];
  warehouse: Deposito | undefined;
  control: Control<FormSchema>;
  startDate: Date | undefined;
  endDate: Date | undefined;
  searchType: string;
  theme: Theme;
  handleSearchTypeSelected: (key: Iterable<Key>) => void;
}

const formSchema = z.object({
  startDate: z.date().optional(),
  endDate: z.date().optional(),
  searchType: z.string(),
});

type FormSchema = z.infer<typeof formSchema>;

const useGerirRotasLayout = (): IUseGerirRotasLayout => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const tabOptions: TabMenu[] = [
    {
      status: 0,
      name: 'Aguardando aprovação',
      path: '/rotas/aguardando-aprovacao',
    },
    {
      status: 1,
      name: 'A conferir',
      path: '/rotas/a-conferir',
    },
    {
      status: 3,
      name: 'Em rota',
      path: '/rotas/em-rota',
    },
    {
      status: 4,
      name: 'Finalizadas',
      path: '/rotas/finalizadas',
    },
    {
      status: -1,
      name: 'Canceladas',
      path: '/rotas/canceladas',
    },
  ];

  const user = useSelector(selectUsuario);
  const [routes, setRoutes] = useState<IRota[]>([]);
  const { startRoutesDate, endRoutesDate, searchType } =
    useSelector(selectRoutesDate);
  const { theme } = useContext(ThemeContext);
  const { control, watch } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      endDate: new Date(endRoutesDate),
      startDate: new Date(startRoutesDate),
      searchType,
    },
  });
  const { startDate, endDate } = watch();
  const cancelToken = axios.CancelToken;
  const dispatch = useAsyncDispatch();

  const [contextHandler, setContextHandler] = useState<IContextGerirRotas>({
    routes: [],
    loading: false,
    refreshRoutes: () => getRoutes(),
  });

  const handleRedirectTo = (path: string): void => {
    navigate(path);
  };

  const getRoutes = async (): Promise<void> => {
    setContextHandler((prev) => ({ ...prev, loading: true }));

    if (startDate && endDate)
      dispatch(
        setRoutesDate({
          startRoutesDate: startDate,
          endRoutesDate: endDate,
          searchType,
        })
      );

    const source: CancelTokenSource = cancelToken.source();
    await dispatch(
      getRoute({
        filter: {
          idStatusRota:
            tabOptions.find((x) => x.path === pathname)?.status || 0,
          dataFim: formatarData(endDate, true, 'input') ?? '',
          dataInicio: formatarData(startDate, true, 'input') ?? '',
          filterType: searchType || '0',
        },
        cancelToken: source,
      })
    )
      .unwrap()
      .then((routesData) => {
        setRoutes(routesData);
        setContextHandler({
          routes: routesData,
          loading: false,
          refreshRoutes: () => getRoutes(),
        });
      })
      .catch((error) => {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_CANCELED') return;

        toast.error('Erro ao carregar rotas, por favor tente mais tarde');
      })
      .finally(() =>
        setContextHandler((prev) => ({ ...prev, loading: false }))
      );
  };

  const handleSearchTypeSelected = (key: Iterable<Key>): void => {
    if (startDate && endDate)
      dispatch(
        setRoutesDate({
          startRoutesDate: startDate,
          endRoutesDate: endDate,
          searchType: Array.from(key)[0] === '0' ? '0' : '1',
        })
      );
  };

  useEffect(() => {
    getRoutes();
  }, [pathname, searchType, startDate, endDate]);

  return {
    handleRedirectTo,
    pathname,
    tabOptions,
    contextHandler,
    rotas: routes,
    warehouse: user?.defaultWarehouse,
    control,
    endDate,
    startDate,
    searchType,
    theme,
    handleSearchTypeSelected,
  };
};

export default useGerirRotasLayout;

import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import React from 'react';
import { IRota } from '../../interfaces/response/Rotas/IRota';
import useRoutesTable from './hooks/useRoutesTable';

export interface IRoutesTable {
  routes: IRota[];
  onRowClick?: (route: IRota) => void;
}

const RoutesTable: React.FC<IRoutesTable> = ({
  routes,
  onRowClick,
}): JSX.Element => {
  const {
    handleRowClick,
    sortDescriptor,
    setSortDescriptor,
    columns,
    sortedRoutes,
    cellData,
  } = useRoutesTable({ routes, onRowClick });

  return (
    <Table
      aria-label="Tabela de rotas"
      selectionMode="single"
      onRowAction={handleRowClick}
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
    >
      <TableHeader columns={columns}>
        {({ key, name }) => (
          <TableColumn key={key} allowsSorting>
            {name}
          </TableColumn>
        )}
      </TableHeader>

      <TableBody items={sortedRoutes} emptyContent="Nenhuma rota no momento">
        {(item) => (
          <TableRow key={item.idRota}>
            {(columnKey) => <TableCell>{cellData(item, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default RoutesTable;

import { Key, useCallback } from 'react';
import { IOrderCheckResponse } from '../../../../../interfaces/response/Orders/IOrderCheckResponse';

export type Header =
  | 'idOrder'
  | 'nrCTE'
  | 'routeName'
  | 'clientName'
  | 'Volume';

type Column = {
  key: Header;
  name: string;
};

export interface UsePrintedOrderTableProps {
  printedOrders: IOrderCheckResponse[];
  onPrintOrder: (order: IOrderCheckResponse) => void;
}

export interface UsePrintedOrderTable {
  handleRowClick: (key: Key) => void;
  columns: Column[];
  cellData: (
    order: IOrderCheckResponse,
    columnKey: Header
  ) => number | string | JSX.Element;
}

const usePrintedOrderTable = ({
  printedOrders,
  onPrintOrder,
}: UsePrintedOrderTableProps): UsePrintedOrderTable => {
  const columns: Column[] = [
    {
      key: 'idOrder',
      name: 'ID',
    },

    {
      key: 'nrCTE',
      name: 'Número do CTE',
    },

    {
      key: 'Volume',
      name: 'Volume',
    },
    {
      key: 'routeName',
      name: 'Nome da Rota',
    },
    {
      key: 'clientName',
      name: 'Nome do cliente',
    },
  ];

  const cellData = useCallback(
    (
      order: IOrderCheckResponse,
      columnKey: Header
    ): number | string | JSX.Element => {
      switch (columnKey) {
        case 'idOrder':
          return order.chaveNFe;
        case 'nrCTE':
          return order.nrCTE;
        case 'routeName':
          return order.nomeRota;
        case 'clientName':
          return order.destinatario;
        case 'Volume':
          return `${order.qtVolumeConferido} / ${order.qtVOlumesTotal}`;
        default:
          return '';
      }
    },
    []
  );

  const handleRowClick = (key: Key): void => {
    const order = printedOrders.find(
      (orderAux) => orderAux.chaveNFe.toString() === key
    );

    if (order) onPrintOrder(order);
  };

  return {
    cellData,
    columns,
    handleRowClick,
  };
};

export default usePrintedOrderTable;

import { SortDescriptor } from '@nextui-org/react';
import {
  Dispatch,
  Key,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ITipoVeiculo } from '../../../../../interfaces/response/TipoVeiculo/ITipoVeiculo';
import { formatarData } from '../../../../../util/format';
import { moneyMask } from '../../../../../util/mask';

export type Header =
  | 'idTipoVeiculo'
  | 'categoria'
  | 'peso'
  | 'valor'
  | 'cubagem_Diametro'
  | 'cubagem_Largura'
  | 'cubagem_Altura'
  | 'ativo'
  | 'dataCadastro';

type Column = {
  key: Header;
  name: string;
};

export interface UseVehicleTypeTableProps {
  vehicleTypes: ITipoVeiculo[];
  onRowClick?: (vehicleType: ITipoVeiculo) => void;
}

export interface UseVehicleTypeTable {
  handleRowClick: (key: Key) => void;
  sortDescriptor: SortDescriptor;
  setSortDescriptor: Dispatch<SetStateAction<SortDescriptor>>;
  columns: Column[];
  sortedVehicleTypes: ITipoVeiculo[];
  cellData: (vehicleType: ITipoVeiculo, columnKey: Header) => number | string;
}

const useVehicleTypeTable = ({
  vehicleTypes,
  onRowClick,
}: UseVehicleTypeTableProps): UseVehicleTypeTable => {
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'idTipoVeiculo',
    direction: 'descending',
  });

  const sortedVehicleTypes = useMemo(() => {
    return [...vehicleTypes].sort((a: ITipoVeiculo, b: ITipoVeiculo) => {
      const first = a[sortDescriptor.column as keyof ITipoVeiculo] as number;
      const second = b[sortDescriptor.column as keyof ITipoVeiculo] as number;

      let cmp = 0;
      if (first < second) cmp = -1;
      if (first > second) cmp = 1;

      return sortDescriptor.direction === 'ascending' ? -cmp : cmp;
    });
  }, [sortDescriptor, vehicleTypes]);

  const columns: Column[] = [
    {
      key: 'idTipoVeiculo',
      name: 'ID',
    },
    {
      key: 'categoria',
      name: 'CATEGORIA',
    },
    {
      key: 'peso',
      name: 'PESO',
    },
    {
      key: 'valor',
      name: 'VALOR PGR',
    },
    {
      key: 'cubagem_Diametro',
      name: 'DIÂMETRO',
    },
    {
      key: 'cubagem_Largura',
      name: 'LARGURA',
    },
    {
      key: 'cubagem_Altura',
      name: 'ALTURA',
    },
    {
      key: 'ativo',
      name: 'ATIVO',
    },
    {
      key: 'dataCadastro',
      name: 'DATA DE CADASTRO',
    },
  ];

  const cellData = useCallback(
    (vehicleType: ITipoVeiculo, columnKey: Header): number | string => {
      switch (columnKey) {
        case 'idTipoVeiculo':
          return vehicleType.idTipoVeiculo;
        case 'categoria':
          return vehicleType.categoria;
        case 'peso':
          return `${moneyMask(vehicleType.peso, '', 2)} kg`;
        case 'valor':
          return moneyMask(vehicleType.valor, 'R$', 2);
        case 'cubagem_Diametro':
          return `${moneyMask(vehicleType.cubagem_Diametro, '', 2)} m`;
        case 'cubagem_Largura':
          return `${moneyMask(vehicleType.cubagem_Largura, '', 2)} m`;
        case 'cubagem_Altura':
          return `${moneyMask(vehicleType.cubagem_Altura, '', 2)} m`;
        case 'ativo':
          return vehicleType.ativo ? 'SIM' : 'NÃO';
        case 'dataCadastro':
          return formatarData(vehicleType.dataCadastro) ?? '';
        default:
          return '';
      }
    },
    []
  );

  const handleRowClick = (key: Key): void => {
    const vehicleType = vehicleTypes.find(
      (vehicleAux) => vehicleAux.idTipoVeiculo.toString() === key
    );
    if (onRowClick && vehicleType) onRowClick(vehicleType);
  };

  return {
    cellData,
    columns,
    handleRowClick,
    setSortDescriptor,
    sortDescriptor,
    sortedVehicleTypes,
  };
};

export default useVehicleTypeTable;

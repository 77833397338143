import { Wrapper } from '@googlemaps/react-wrapper';
import {
  CircularProgress,
  Divider,
  Select,
  SelectItem,
  Spacer,
  Tab,
  Tabs,
} from '@nextui-org/react';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Outlet } from 'react-router-dom';
import { IRota } from '../../interfaces/response/Rotas/IRota';
import useGerirRotasLayout from './hooks/useGerirRotasLayout';

import DatePickerInput from '../../components/DatepickerInput/DatepickerInput';
import { CreateMap } from './components/CreateMap/CreateMap';

export const Context = React.createContext<IContextGerirRotas>({
  routes: [],
  loading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refreshRoutes: async () => {},
});

export interface IContextGerirRotas {
  routes: IRota[];
  loading: boolean;

  refreshRoutes: () => Promise<void>;
}

const GerirRotas = (): JSX.Element => {
  const {
    tabOptions,
    pathname,
    handleRedirectTo,
    contextHandler,
    warehouse,
    rotas,
    control,
    endDate,
    startDate,
    searchType,
    theme,
    handleSearchTypeSelected,
  } = useGerirRotasLayout();
  return (
    <Wrapper
      apiKey={process.env.REACT_APP_MAP_API_KEY ?? ''}
      libraries={['marker', 'drawing']}
      version="beta"
    >
      <div className="relative flex w-1/2 flex-row">
        {!contextHandler.loading ? (
          <CreateMap
            height="calc(100vh - 65px)"
            width="30%"
            rotas={rotas}
            warehouse={warehouse}
          />
        ) : (
          <div
            style={{
              height: 'calc(100vh - 65px)',
              width: '30%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress label="Baixando Pedidos" />
          </div>
        )}

        <div
          style={{
            width: '70%',
            display: 'flex',
            flexDirection: 'column',
            padding: '24px',
          }}
        >
          {/* Title */}
          <h1 className="text-2xl font-bold text-foreground-800">Rotas</h1>

          <Divider className="my-4" />

          <Tabs
            variant="underlined"
            selectedKey={pathname}
            onSelectionChange={(key) => handleRedirectTo(key.toString())}
          >
            {tabOptions.map(({ name, path }) => (
              <Tab key={path} title={name} />
            ))}
          </Tabs>

          <Spacer y={4} />
          <div className="flex flex-row items-center justify-between">
            <div className="mx-4 flex flex-row gap-4">
              <Controller
                control={control}
                name="searchType"
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Tipo de Filtro"
                    variant="flat"
                    size="sm"
                    placeholder="Selecione uma data para realizar o filtro de CTEs"
                    classNames={{
                      label: 'font-bold',
                      popoverContent: `${theme}`,
                    }}
                    selectedKeys={searchType}
                    onBlur={field.onBlur}
                    className={`${theme}`}
                    onSelectionChange={(keys) => {
                      field.onChange(keys);
                      handleSearchTypeSelected(keys);
                    }}
                    style={{ width: 200 }}
                  >
                    <SelectItem key={0} value="0" textValue="Data de Criação">
                      <p className="text-foreground-700">Data de Criação</p>
                    </SelectItem>
                    <SelectItem key={1} value="1" textValue="Data de Saída">
                      <p className="text-foreground-700">Data de Saída</p>
                    </SelectItem>
                  </Select>
                )}
              />
            </div>
            {/* Date Filter */}
            <div className="flex flex-row gap-4">
              <DatePickerInput
                control={control}
                name="startDate"
                datePickerProps={{
                  selectsStart: true,
                  startDate,
                  endDate,
                  maxDate: endDate,
                }}
                inputProps={{
                  size: 'sm',
                  label: 'de',
                  variant: 'flat',
                }}
              />

              <DatePickerInput
                control={control}
                name="endDate"
                datePickerProps={{
                  selectsEnd: true,
                  startDate,
                  endDate,
                  minDate: startDate,
                }}
                inputProps={{
                  size: 'sm',
                  label: 'até',
                  variant: 'flat',
                }}
              />
            </div>
          </div>
          <Spacer y={4} />

          <Context.Provider value={contextHandler}>
            <Outlet />
          </Context.Provider>
        </div>
      </div>
    </Wrapper>
  );
};

export default GerirRotas;

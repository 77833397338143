import { Input } from '@nextui-org/react';
import React from 'react';
import { IPedido } from '../../../../../../../interfaces/response/Pedido/IPedido';
import { formatarData } from '../../../../../../../util/format';
import {
  formatarMedidaGenerico,
  formatarMoeda,
} from '../../../../../../../util/mask';

interface ITabProps {
  pedido: IPedido;
}
const DetalheCTE = ({ pedido }: ITabProps): JSX.Element => {
  return (
    <div>
      <div className="flex w-full flex-col gap-2">
        {/* Linha 1: Peso Taxado, Peso Real, Peso Cubado, Data Emissao */}
        <div className="flex w-full flex-wrap gap-4">
          <div className="flex-1">
            <Input
              size="sm"
              label="Peso Taxado"
              value={formatarMedidaGenerico(
                pedido?.pesoTaxado,
                pedido?.unidadeMedidaPesoTaxado
              )}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="sm"
              label="Peso Real"
              value={formatarMedidaGenerico(
                pedido?.pesoReal,
                pedido?.unidadeMedidaPesoReal
              )}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="sm"
              label="Peso Cubado"
              value={formatarMedidaGenerico(
                pedido?.pesoCubado,
                pedido?.unidadeMedidaPesoCubado
              )}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="sm"
              label="Data Emissão"
              value={formatarData(pedido?.dtEmissao, false, 'normal') || ''}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="sm"
              label="Data Limite Entrega"
              value={
                formatarData(pedido?.dtLimiteEntrega, true, 'normal') || ''
              }
              disabled
            />
          </div>
        </div>
        {/* Linha 2: Natureza Operacao, Tipo Serviço, Modal */}
        <div className="flex w-full flex-wrap gap-4">
          <div className="flex-1">
            <Input
              size="sm"
              label="Natureza de Operação"
              value={pedido?.natOp}
              disabled
            />
          </div>

          <div className="flex-1">
            <Input
              size="sm"
              label="Tipo Serviço"
              value={pedido?.tpServ.toUpperCase()}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="sm"
              label="Modal"
              value={pedido?.modal.toUpperCase()}
              disabled
            />
          </div>
        </div>
        {/* Linha 3: Valor Carga, Valor Tributo, Valor Averbado */}
        <div className="flex w-full flex-wrap gap-4">
          <div className="flex-1">
            <Input
              size="sm"
              label="Valor Carga"
              value={formatarMoeda(pedido?.vlCarga)}
              disabled
            />
          </div>

          <div className="flex-1">
            <Input
              size="sm"
              label="Valor Tributo"
              value={formatarMoeda(pedido?.vlTotalTributo)}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="sm"
              label="Valor Averbado"
              value={formatarMoeda(pedido?.vlCargaAverbado)}
              disabled
            />
          </div>

          <div className="flex-1">
            <Input
              size="sm"
              label="Valor ICMS"
              value={formatarMoeda(pedido?.vTotTribICMS)}
              disabled
            />
          </div>

          <div className="flex-1">
            <Input
              size="sm"
              label="Valor Receber"
              value={formatarMoeda(pedido?.vlTotalReceber)}
              disabled
            />
          </div>
        </div>
        {/* Linha 4: Ad Valorem, Frete Peso, GRIS, Imposto Repassado */}
        <div className="flex w-full flex-wrap gap-4">
          <div className="flex-1">
            <Input
              size="sm"
              label="Ad Valorem"
              value={formatarMoeda(pedido?.adValorem)}
              disabled
            />
          </div>

          <div className="flex-1">
            <Input
              size="sm"
              label="Frete Peso"
              value={formatarMoeda(pedido?.fretePeso)}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="sm"
              label="GRIS"
              value={formatarMoeda(pedido?.gris)}
              disabled
            />
          </div>

          <div className="flex-1">
            <Input
              size="sm"
              label="Imposto Repassado"
              value={formatarMoeda(pedido?.vTotTribICMS)}
              disabled
            />
          </div>
        </div>
        {/* Linha 5: VOlume Carga, Volumes, Chave NFE */}
        <div className="flex w-full flex-wrap gap-4">
          <div className="flex-1/2">
            <Input
              size="sm"
              label="Volume Carga"
              value={formatarMedidaGenerico(
                pedido?.volume,
                pedido?.unidadeMedidaVolume
              )}
              disabled
            />
          </div>

          <div className="flex-1/2">
            <Input
              size="sm"
              label="Volumes"
              value={pedido?.volumes.toString()}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="sm"
              label="Chave NFe"
              value={pedido?.chaveNFe}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetalheCTE;

import { Input, InputProps } from '@nextui-org/react';
import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { FaCalendarAlt } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';

interface DatePickerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  control: Control<TFieldValues>;
  name: TName;
  inputProps?: InputProps;
  datePickerProps?: Partial<ReactDatePickerProps>;
}

const DatePickerInput = <T extends FieldValues>({
  control,
  name,
  datePickerProps,
  inputProps,
}: DatePickerProps<T>): JSX.Element => {
  const hideDefaultClearButtonClasses =
    '[&_button]:opacity-0 [&_button]:p-2 [&_button]:h-8 [&_button]:mt-6';

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, ...fieldProps } }) => {
        return (
          <div>
            <DatePicker
              {...fieldProps}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select date"
              {...datePickerProps}
              selected={value}
              popperProps={{
                className: 'z-50',
              }}
              wrapperClassName={`w-full ${
                datePickerProps?.isClearable
                  ? hideDefaultClearButtonClasses
                  : ''
              }`}
              customInput={
                <Input
                  {...inputProps}
                  type="datetime"
                  classNames={{
                    label: 'font-bold',
                    innerWrapper: [
                      'flex',
                      'flex-row',
                      'items-center',
                      'justify-center',
                    ],
                    ...inputProps?.classNames,
                  }}
                  startContent={
                    <div className="p-0.5">
                      <FaCalendarAlt className="text-foreground-600" />
                    </div>
                  }
                  endContent={
                    <div>
                      {datePickerProps?.isClearable && (
                        <div className="mr-0.5">
                          <IoMdCloseCircle className="h-5 w-5 text-foreground-600" />
                        </div>
                      )}
                    </div>
                  }
                />
              }
            />
          </div>
        );
      }}
    />
  );
};

export default DatePickerInput;

import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import React from 'react';
import { MdLogout } from 'react-icons/md';
import useChangePasswordModal from './hooks/useChangePasswordModal';

const ChangePasswordModal = (): JSX.Element => {
  const {
    onOpen,
    isOpen,
    onOpenChange,
    theme,
    handleClose,
    handleSubmit,
    handleChangePassword,
    errors,
    register,
    isLoading,
    isValid,
  } = useChangePasswordModal();

  return (
    <>
      <Button onPress={onOpen} size="md" startContent={<MdLogout />}>
        Trocar senha
      </Button>

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className={`${theme}`}
        onClose={handleClose}
      >
        <ModalContent>
          <form onSubmit={handleSubmit(handleChangePassword)}>
            <ModalHeader className="text-default-600">
              Alterar senha
            </ModalHeader>

            <ModalBody>
              <p className="text-sm text-default-500">
                Entre com seus dados atuais e a nova senha para realizar sua
                alteração:
              </p>

              <Input
                size="md"
                type="password"
                label="Senha atual"
                variant="underlined"
                autoFocus
                classNames={{
                  label: 'font-bold',
                }}
                errorMessage={errors.currentPassword?.message}
                {...register('currentPassword')}
              />

              <Input
                size="md"
                type="password"
                label="Nova senha"
                variant="underlined"
                classNames={{
                  label: 'font-bold',
                }}
                errorMessage={errors.newPassword?.message}
                {...register('newPassword')}
              />

              <Input
                size="md"
                type="password"
                label="Confirme a nova senha"
                variant="underlined"
                classNames={{
                  label: 'font-bold',
                }}
                errorMessage={errors.newPasswordConfirmation?.message}
                {...register('newPasswordConfirmation')}
              />
            </ModalBody>

            <ModalFooter>
              <Button color="danger" onPress={handleClose}>
                Cancelar
              </Button>

              <Button type="submit" isLoading={isLoading} isDisabled={!isValid}>
                Alterar senha
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;

import { zodResolver } from '@hookform/resolvers/zod';
import { useDisclosure } from '@nextui-org/react';
import { useContext, useEffect, useState } from 'react';
import {
  Control,
  FieldErrors,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { z } from 'zod';
import {
  Theme,
  ThemeContext,
} from '../../../contexts/ThemeContext/ThemeContext';
import { ICadastraTabelaPrecoRequest } from '../../../interfaces/request/TabelaPreco/ICadastraTabelaPrecoRequest';
import { IEditaTabelaPrecoRequest } from '../../../interfaces/request/TabelaPreco/IEditaTabelaPrecoRequest';
import { ITabelaPreco } from '../../../interfaces/response/TabelaPreco/ITabelaPreco';
import { ITipoVeiculo } from '../../../interfaces/response/TipoVeiculo/ITipoVeiculo';
import {
  CadastrarTabelaPreco,
  EditarTabelaPreco,
  ObterTipoVeiculo,
} from '../../../redux/features/clientsData/clientsDataThunk';
import { selectUsuario } from '../../../redux/features/generalData/generalDataSelectors';
import { useAsyncDispatch } from '../../../redux/store';
import { cepMask } from '../../../util/mask';

export enum DriverType {
  frota = 'frota',
  agregado = 'agregado',
}

const formSchema = z.object({
  startZipCode: z
    .string()
    .min(1, 'CEP inicial é obrigatório')
    .length(9, 'CEP inválido')
    .transform((value) => value.replace(/\D+/g, '')),
  endZipCode: z
    .string()
    .min(1, 'CEP final é obrigatório')
    .length(9, 'CEP inválido')
    .transform((value) => value.replace(/\D+/g, '')),
  value: z.string().min(1, 'Valor da tabela é obrigatório'),
  vehicleType: z.string().min(1, 'Tipo de veículo é obrigatório'),
});

type FormSchema = z.infer<typeof formSchema>;

interface UseRegisterLocationPriceModalProps {
  onUpdate?: () => void;
}

export interface UseRegisterLocationPriceModal {
  errors: FieldErrors<FormSchema>;
  handleSubmitLocationPrice: (data: FormSchema) => Promise<void>;
  handleClose: () => void;
  handleSubmit: UseFormHandleSubmit<FormSchema>;
  handleOpenEdit: (locationPriceAux: ITabelaPreco) => void;
  isLoading: boolean;
  isOpen: boolean;
  isValid: boolean;
  onOpen: () => void;
  onOpenChange: () => void;
  register: UseFormRegister<FormSchema>;
  theme: Theme;
  control: Control<FormSchema>;
  setValue: UseFormSetValue<FormSchema>;
  values: FormSchema;
  vehicleTypeOptions: ITipoVeiculo[];
  locationPrice: ITabelaPreco | null;
}

const useRegisterLocationPriceModal = ({
  onUpdate,
}: UseRegisterLocationPriceModalProps): UseRegisterLocationPriceModal => {
  const dispatch = useAsyncDispatch();
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const { theme } = useContext(ThemeContext);
  const user = useSelector(selectUsuario);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [locationPrice, setLocationPrice] = useState<ITabelaPreco | null>(null);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<ITipoVeiculo[]>(
    []
  );
  const defaultValues: FormSchema = {
    endZipCode: '',
    startZipCode: '',
    value: '0,00',
    vehicleType: '',
  };
  const {
    register,
    handleSubmit,
    clearErrors,
    formState,
    reset,
    control,
    setValue,
    watch,
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resetOptions: {
      keepDefaultValues: true,
    },
  });
  const { errors, isValid } = formState;
  const values = watch();

  const handleClose = (): void => {
    onClose();
    setLocationPrice(null);
    clearErrors();
    reset();
  };

  const handleCreateLocationPrice = async (data: FormSchema): Promise<void> => {
    if (!user) return;

    const { _Id: userId } = user;
    const { endZipCode, startZipCode, value, vehicleType } = data;

    const body: ICadastraTabelaPrecoRequest = {
      idDeposito: user.defaultWarehouse.idDeposito,
      cEPFinal: endZipCode,
      cEPInicio: startZipCode,
      idTipoVeiculo: Number(vehicleType),
      valor: Number(value.replaceAll('.', '').replace(',', '.')),
      usuarioCadastro: userId,
    };

    setIsLoading(true);
    await dispatch(
      CadastrarTabelaPreco({
        body,
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Tabela preço cadastrada com sucesso!');
        handleClose();
        if (onUpdate) onUpdate();
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao realizar o cadastro da tabela preço, por favor tente mais tarde.'
        );
      })
      .finally(() => setIsLoading(false));
  };

  const handleEditLocationPrice = async (data: FormSchema): Promise<void> => {
    if (!user || !locationPrice) return;

    const { _Id: userId } = user;
    const { endZipCode, startZipCode, value, vehicleType } = data;

    const body: IEditaTabelaPrecoRequest = {
      cEPFinal: endZipCode,
      cEPInicio: startZipCode,
      idTipoVeiculo: Number(vehicleType),
      valor: Number(value.replaceAll('.', '').replace(',', '.')),
      idTabelaPreco: locationPrice.idTabelaPreco,
      usuarioAlteracao: userId,
    };

    setIsLoading(true);
    await dispatch(
      EditarTabelaPreco({
        body,
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Tabela preço editada com sucesso!');
        handleClose();
        if (onUpdate) onUpdate();
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao realizar a alteração da tabela preço, por favor tente mais tarde.'
        );
      })
      .finally(() => setIsLoading(false));
  };

  const handleSubmitLocationPrice = async (data: FormSchema): Promise<void> => {
    if (locationPrice) handleEditLocationPrice(data);
    else handleCreateLocationPrice(data);
  };

  const handleGetVehicleTypes = async (): Promise<void> => {
    await dispatch(ObterTipoVeiculo())
      .unwrap()
      .then((response) => setVehicleTypeOptions(response.tipoVeiculos))
      .catch(() => {
        handleClose();
        toast.error(
          'Ocorreu um erro ao buscar os tipos de veículo, por favor tente mais tarde.'
        );
      });
  };

  useEffect(() => {
    handleGetVehicleTypes();
  }, []);

  const handleOpenEdit = (locationPriceAux: ITabelaPreco): void => {
    setLocationPrice(locationPriceAux);
    setValue('startZipCode', cepMask(locationPriceAux?.cepInicio) ?? '');
    setValue('endZipCode', cepMask(locationPriceAux?.cepFinal) ?? '');
    setValue(
      'value',
      Number(locationPriceAux?.valor).toFixed(2).replace('.', ',') ?? '0,00'
    );
    setValue('vehicleType', locationPriceAux?.idTipoVeiculo.toString() ?? '');
    onOpen();
  };

  return {
    errors,
    handleSubmitLocationPrice,
    handleClose,
    handleSubmit,
    handleOpenEdit,
    isLoading,
    isOpen,
    isValid,
    onOpen,
    onOpenChange,
    register,
    theme,
    control,
    setValue,
    values,
    vehicleTypeOptions,
    locationPrice,
  };
};

export default useRegisterLocationPriceModal;

import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import React from 'react';
import { IPlanilha } from '../../../../../interfaces/response/Planilha/IPlanilha';
import useSpreadsheetListTable, {
  Header,
} from './hooks/useSpreadsheetListTable';

export interface ISpreadsheetListTable {
  spreadsheets: IPlanilha[];
}

const SpreadsheetListTable: React.FC<ISpreadsheetListTable> = ({
  spreadsheets,
}): JSX.Element => {
  const {
    cellData,
    columns,
    setSortDescriptor,
    sortDescriptor,
    sortedSpreadsheets,
  } = useSpreadsheetListTable({
    spreadsheets,
  });

  return (
    <Table
      aria-label="Tabela da rota selecionada"
      isHeaderSticky
      selectionMode="single"
      classNames={{
        wrapper: ['xl:max-h-[60vh]', 'max-h-[50vh]'],
      }}
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
    >
      <TableHeader columns={columns}>
        {({ key, name }) => (
          <TableColumn key={key} allowsSorting>
            {name}
          </TableColumn>
        )}
      </TableHeader>

      <TableBody
        items={sortedSpreadsheets}
        emptyContent="Nenhuma rota no momento"
      >
        {(item) => (
          <TableRow key={item.idRemessaPedido}>
            {(columnKey) => (
              <TableCell>
                {columnKey === 'Ind Processada' || columnKey === 'Ações' ? (
                  <>{cellData(item, columnKey as Header)}</>
                ) : (
                  <p className="text-foreground-600">
                    {cellData(item, columnKey as Header)}
                  </p>
                )}
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default SpreadsheetListTable;
